.main-content,body,html{min-height:100%; }

.alert,.well{border-radius:0}

.nav-list > li .submenu > li > a:hover,.nav-list > li > a,a:active,a:focus{text-decoration:none; }

.main-container:before{display:block; content:""; position:absolute; z-index:-2; width:100%; max-width:inherit; bottom:0; top:0;  }

.main-container.container,.rtl .main-container.container{padding-left:0; padding-right:0}

.main-container.container:before{-webkit-box-shadow:0 0 0 1px rgba(0,0,0,.1); box-shadow:0 0 0 1px rgba(0,0,0,.1); width:inherit}

@media (max-width:767px){.main-container.container:before{-webkit-box-shadow:none; box-shadow:none; width:100%}}

.main-content{margin-left:0; padding:0}

.main-content:after,.main-content:before{content:" "; display:table}

.nav-list li.active > a:after,.nav-list > li:before,.nav-list > li > .submenu:before,.nav-list > li > .submenu > li:before,.sidebar .sidebar-toggle:before,.sidebar.menu-min .nav-list > li > .submenu:after,.sidebar:before,li.divider:before{content:""}

.main-content:after{clear:both}

.sidebar+.main-content{margin-left:190px}

.ace-nav{height:100%; margin:0!important}

.ace-nav > li{line-height:45px; height:45px; border-left:1px solid #E1E1E1; padding:0; position:relative; float:left}

.ace-nav > li:first-child{border-left-width:0}

.ace-nav > li > a{background-color:#2E6589; color:#FFF; display:block; line-height:inherit; text-align:center; height:100%; width:auto; min-width:50px; padding:0 8px; position:relative}

.ace-nav > li > a > .ace-icon{display:inline-block; font-size:16px; color:#FFF; text-align:center; width:20px}

.ace-nav > li > a > .badge{position:relative; top:-4px; left:2px; padding-right:5px; padding-left:5px}

.ace-nav > li.open > a,.ace-nav > li > a:focus,.ace-nav > li > a:hover{background-color:#2c5976; color:#FFF}

.ace-nav > li.blue > a{background-color:#2E6589}

.ace-nav > li.blue > a:focus,.ace-nav > li.blue > a:hover,.ace-nav > li.open.blue > a{background-color:#2c5976}

.ace-nav > li.grey > a{background-color:#555}

.ace-nav > li.grey > a:focus,.ace-nav > li.grey > a:hover,.ace-nav > li.open.grey > a{background-color:#4b4b4b}

.ace-nav > li.purple > a{background-color:#892E65}

.ace-nav > li.open.purple > a,.ace-nav > li.purple > a:focus,.ace-nav > li.purple > a:hover{background-color:#762c59}

.ace-nav > li.green > a { background-color:#2E8965}

.ace-nav > li.green > a:focus,.ace-nav > li.green > a:hover,.ace-nav > li.open.green > a{background-color:#2c7659}

.ace-nav > li.light-blue > a{background-color:#62A8D1}

.ace-nav > li.light-blue > a:focus,.ace-nav > li.light-blue > a:hover,.ace-nav > li.open.light-blue > a{background-color:#579ec8}

.ace-nav > li.light-blue2 > a{background-color:#42A8E1}

.ace-nav > li.light-blue2 > a:focus,.ace-nav > li.light-blue2 > a:hover,.ace-nav > li.open.light-blue2 > a{background-color:#359fd9}

.ace-nav > li.red > a{background-color:#B74635}

.ace-nav > li.open.red > a,.ace-nav > li.red > a:focus,.ace-nav > li.red > a:hover{background-color:#a34335}

.ace-nav > li.light-green > a{background-color:#9ABC32}

.ace-nav > li.light-green > a:focus,.ace-nav > li.light-green > a:hover,.ace-nav > li.open.light-green > a{background-color:#8ba832}

.ace-nav > li.light-purple > a {background-color:#CB6FD7}

.ace-nav > li.light-purple > a:focus,.ace-nav > li.light-purple > a:hover,.ace-nav > li.open.light-purple > a{background-color:#c263ce}

.ace-nav > li.light-orange > a{background-color:#F79263}

.ace-nav > li.light-orange > a:focus,.ace-nav > li.light-orange > a:hover,.ace-nav > li.open.light-orange > a{background-color:#f28653}

.ace-nav > li.light-pink > a{background-color:#F4DAE5}

.ace-nav > li.light-pink > a:focus,.ace-nav > li.light-pink > a:hover,.ace-nav > li.open.light-pink > a{background-color:#eeccda}

.ace-nav > li.dark > a{background-color:#404040}

.ace-nav > li.dark > a:focus,.ace-nav > li.dark > a:hover,.ace-nav > li.open.dark > a{background-color:#363636}

.ace-nav > li.white-opaque > a{background-color:rgba(255,255,255,.8)}

.ace-nav > li.open.white-opaque > a,.ace-nav > li.white-opaque > a:focus,.ace-nav > li.white-opaque > a:hover{background-color:rgba(245,245,245,.8)}

.ace-nav > li.dark-opaque > a,.ace-nav > li.dark-opaque > a:focus,.ace-nav > li.dark-opaque > a:hover,.ace-nav > li.open.dark-opaque > a{background-color:rgba(0,0,0,.2)}

.ace-nav > li.transparent > a{background-color:transparent}

.ace-nav > li.open.transparent > a,.ace-nav > li.transparent > a:focus,.ace-nav > li.transparent > a:hover{background-color:rgba(0,0,0,.1)}

.ace-nav > li.light-10 > a{background-color:rgba(255,255,255,.1)}

.ace-nav > li.light-10 > a:focus,.ace-nav > li.light-10 > a:hover,.ace-nav > li.open.light-10 > a{background-color:rgba(255,255,255,.2)}

.ace-nav > li.dark-10 > a{background-color:rgba(0,0,0,.1)}

.ace-nav > li.dark-10 > a:focus,.ace-nav > li.dark-10 > a:hover,.ace-nav > li.open.dark-10 > a{background-color:rgba(0,0,0,.2)}

.ace-nav > li.margin-4{margin-left:4px}

.ace-nav > li.margin-3{margin-left:3px}

.ace-nav > li.margin-2{margin-left:2px}

.ace-nav > li.margin-1{margin-left:1px}

.ace-nav > li.no-border{border-width:0!important}

.ace-nav > li .dropdown-menu{z-index:1031}

.ace-nav .nav-user-photo{margin:-4px 8px 0 0; border-radius:100%; border:2px solid #FFF; max-width:40px}

.ace-nav li.dropdown-footer a .ace-icon{display:inline-block; width:1.25em; text-align:center}

@media only screen and (max-height:540px){.ace-nav > li.dropdown-modal > .dropdown-menu{position:fixed; margin-top:auto; z-index:1052}

.ace-nav > li.dropdown-modal > .dropdown-menu:after,.ace-nav > li.dropdown-modal > .dropdown-menu:before{display:none}

.ace-nav > li.dropdown-modal > .user-menu > li > a{padding-top:6px; padding-bottom:6px}

.ace-nav > li.dropdown-modal.open:before{background-color:#000; opacity:.5; border-width:0; position:fixed; left:0; right:0; top:0; bottom:0; content:""; display:block; height:2000px; width:auto; z-index:1051}

}

@media only screen and (max-width:767px){.ace-nav > li:nth-last-child(4) > .dropdown-menu.dropdown-menu-right{right:-100px}

.ace-nav > li:nth-last-child(4) > .dropdown-menu.dropdown-menu-right:after,.ace-nav > li:nth-last-child(4) > .dropdown-menu.dropdown-menu-right:before{right:120px}

.ace-nav > li:nth-last-child(3) > .dropdown-menu.dropdown-menu-right{right:-80px}

.ace-nav > li:nth-last-child(3) > .dropdown-menu.dropdown-menu-right:after,.ace-nav > li:nth-last-child(3) > .dropdown-menu.dropdown-menu-right:before{right:100px}

.user-menu.dropdown-close.dropdown-menu-right{right:0!important}

}

@media only screen and (min-width:480px) and (max-width:767px){.navbar.navbar-collapse .ace-nav > li:nth-last-child(2):nth-child(1) > .dropdown-menu.dropdown-menu-right,.navbar.navbar-collapse .ace-nav > li:nth-last-child(2):nth-child(2) > .dropdown-menu.dropdown-menu-right{left:auto; right:-60px}

.navbar.navbar-collapse .ace-nav > li:nth-last-child(2):nth-child(1) > .dropdown-menu.dropdown-menu-right:after,.navbar.navbar-collapse .ace-nav > li:nth-last-child(2):nth-child(1) > .dropdown-menu.dropdown-menu-right:before,.navbar.navbar-collapse .ace-nav > li:nth-last-child(2):nth-child(2) > .dropdown-menu.dropdown-menu-right:after,.navbar.navbar-collapse .ace-nav > li:nth-last-child(2):nth-child(2) > .dropdown-menu.dropdown-menu-right:before{right:80px; left:auto}

}

@media only screen and (max-width:480px){.ace-nav > li:nth-last-child(4) > .dropdown-menu.dropdown-menu-right{right:-120px}

.ace-nav > li:nth-last-child(4) > .dropdown-menu.dropdown-menu-right:after,.ace-nav > li:nth-last-child(4) > .dropdown-menu.dropdown-menu-right:before{right:140px}

.ace-nav > li:nth-last-child(3) > .dropdown-menu.dropdown-menu-right{right:-90px}

.ace-nav > li:nth-last-child(3) > .dropdown-menu.dropdown-menu-right:after,.ace-nav > li:nth-last-child(3) > .dropdown-menu.dropdown-menu-right:before{right:110px}

.ace-nav > li:nth-last-child(2) > .dropdown-menu.dropdown-menu-right{right:-50px}

.ace-nav > li:nth-last-child(2) > .dropdown-menu.dropdown-menu-right:after,.ace-nav > li:nth-last-child(2) > .dropdown-menu.dropdown-menu-right:before{right:70px}

}

@media only screen and (max-width:479px){.ace-nav > li:nth-last-child(4) > .dropdown-menu.dropdown-menu-right{left:-10px; right:auto}

.ace-nav > li:nth-last-child(4) > .dropdown-menu.dropdown-menu-right:after,.ace-nav > li:nth-last-child(4) > .dropdown-menu.dropdown-menu-right:before{right:auto; left:30px}

.ace-nav > li:nth-last-child(3) > .dropdown-menu.dropdown-menu-right{left:-50px; right:auto}

.ace-nav > li:nth-last-child(3) > .dropdown-menu.dropdown-menu-right:after,.ace-nav > li:nth-last-child(3) > .dropdown-menu.dropdown-menu-right:before{left:75px; right:auto}

.ace-nav > li:nth-last-child(2) > .dropdown-menu.dropdown-menu-right{left:-70px; right:auto}

.ace-nav > li:nth-last-child(2) > .dropdown-menu.dropdown-menu-right:after,.ace-nav > li:nth-last-child(2) > .dropdown-menu.dropdown-menu-right:before{left:90px; right:auto}

.navbar:not(.navbar-collapse) .navbar-header{float:none!important; display:block}

.navbar:not(.navbar-collapse) .ace-nav{display:block; float:none!important; text-align:center; background-color:rgba(0,0,0,.15); border:1px solid rgba(0,0,0,.1); border-width:1px 0 0; letter-spacing:-3px}

.navbar:not(.navbar-collapse) .ace-nav > li{display:inline-block; float:none!important; text-align:left; letter-spacing:normal}

.navbar:not(.navbar-collapse) .ace-nav > li:first-child{border-left:1px solid rgba(255,255,255,.4)}

.navbar:not(.navbar-collapse) .ace-nav > li:last-child{border-right:1px solid rgba(255,255,255,.4)}

}

@media only screen and (max-width:319px){.ace-nav > li:nth-last-child(2) > .dropdown-menu.dropdown-menu-right{left:-110px; right:auto}

.ace-nav > li:nth-last-child(2) > .dropdown-menu.dropdown-menu-right:after,.ace-nav > li:nth-last-child(2) > .dropdown-menu.dropdown-menu-right:before{left:130px; right:auto}

.ace-nav > li:nth-child(2):nth-last-child(2) > .dropdown-menu.dropdown-menu-right{left:-85px; right:auto}

.ace-nav > li:nth-child(2):nth-last-child(2) > .dropdown-menu.dropdown-menu-right:after,.ace-nav > li:nth-child(2):nth-last-child(2) > .dropdown-menu.dropdown-menu-right:before{left:105px; right:auto}

.ace-nav > li:nth-child(1):nth-last-child(3) > .dropdown-menu.dropdown-menu-right{left:-35px; right:auto}

.ace-nav > li:nth-child(1):nth-last-child(3) > .dropdown-menu.dropdown-menu-right:after,.ace-nav > li:nth-child(1):nth-last-child(3) > .dropdown-menu.dropdown-menu-right:before{left:55px; right:auto}

.ace-nav > li:nth-child(1):nth-last-child(2) > .dropdown-menu.dropdown-menu-right{left:-60px; right:auto}

.ace-nav > li:nth-child(1):nth-last-child(2) > .dropdown-menu.dropdown-menu-right:after,.ace-nav > li:nth-child(1):nth-last-child(2) > .dropdown-menu.dropdown-menu-right:before{left:75px; right:auto}

.ace-nav > li > a{padding:0 5px!important}

}

.user-menu > li > a{padding:4px 12px}

.user-menu > li > a > .ace-icon{margin-right:6px; font-size:120%}

.user-info{max-width:100px; display:inline-block; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; text-align:left; vertical-align:top; line-height:15px; position:relative; top:6px}

.user-info small{display:block}

@media (min-width:480px) and (max-width:540px),(max-width:360px){.navbar:not(.navbar-collapse) .navbar-buttons .user-menu{padding-top:42px!important}

.navbar:not(.navbar-collapse) .navbar-buttons .nav-user-photo{margin-right:0}

.navbar:not(.navbar-collapse) .navbar-buttons .user-info{position:absolute; margin-top:40px; margin-left:1px; right:2px; z-index:1032; color:#777; font-size:14px; width:156px; max-width:156px; padding-left:8px; padding-right:8px; height:32px; line-height:26px!important; display:none; border-bottom:1px solid #E5E5E5; text-align:center; vertical-align:none; cursor:text}

.navbar:not(.navbar-collapse) .navbar-buttons .user-info > small{display:inline; opacity:1}

li.open .user-info{display:inline-block!important}

}

@media (max-width:360px){.navbar.navbar-collapse .navbar-buttons .user-menu{padding-top:42px!important}

.navbar.navbar-collapse .navbar-buttons .nav-user-photo{margin-right:0}

.navbar.navbar-collapse .navbar-buttons .user-info{position:absolute; margin-top:40px; margin-left:1px; right:2px; z-index:1032; color:#777; font-size:14px; width:156px; max-width:156px; padding-left:8px; padding-right:8px; height:32px; line-height:26px!important; display:none; border-bottom:1px solid #E5E5E5; text-align:center; vertical-align:none; cursor:text}

.navbar.navbar-collapse .navbar-buttons .user-info > small{display:inline; opacity:1}

li.open .user-info{display:inline-block!important; right:2px}

}

@media (min-width:992px){.user-min .user-menu{padding-top:42px!important}

.user-min .nav-user-photo{margin-right:0}

.user-min .user-info{position:absolute; margin-top:40px; margin-left:1px; z-index:1032; color:#777; font-size:14px; width:156px; max-width:156px; padding-left:8px; padding-right:8px; height:32px; line-height:26px!important; display:none; border-bottom:1px solid #E5E5E5; text-align:center; vertical-align:none; cursor:text; right:-3px}

.user-min .user-info > small{display:inline; opacity:1}

.user-min.open .user-info{display:inline-block!important}

}

.btn,.dropdown-colorpicker a{cursor:pointer}

@media (max-height:540px) and (min-width:480px) and (max-width:540px),(max-height:540px) and (max-width:360px){.navbar:not(.navbar-collapse) .ace-nav > li.dropdown-modal .user-info{position:fixed; right:auto; margin-top:auto; max-width:none}

}

@media (max-height:540px) and (max-width:360px){.navbar.navbar-collapse .ace-nav > li.dropdown-modal .user-info{position:fixed; right:auto; margin-top:auto; max-width:none}

}

@media (max-height:540px) and (min-width:992px){.ace-nav > li.dropdown-modal.user-min .user-info{position:fixed; right:auto; margin-top:auto; max-width:none}

}

@media only screen and (max-width:991px){.navbar.navbar-collapse{max-height:none}

.navbar .navbar-collapse,.navbar .navbar-header{margin-left:0!important; margin-right:0!important}

.navbar.navbar-collapse .navbar-header{float:none!important; min-height:45px}

.navbar.navbar-collapse .navbar-buttons{min-height:0; padding-left:0; padding-right:0; border:1px solid rgba(255,255,255,.4); border-width:1px 0 0; width:100%}

.navbar.navbar-collapse .navbar-buttons.in{overflow:visible!important}

.navbar.navbar-collapse .navbar-buttons > .ace-nav{display:block; float:none!important; text-align:center; background-color:transparent!important; border-width:0; letter-spacing:-3px}

.navbar.navbar-collapse .navbar-buttons > .ace-nav > li{display:inline-block; float:none!important; text-align:left; letter-spacing:normal}

.navbar.navbar-collapse .navbar-buttons > .ace-nav > li:first-child{border-left:1px solid rgba(255,255,255,.4)}

.navbar.navbar-collapse .navbar-buttons > .ace-nav > li:last-child{border-right:1px solid rgba(255,255,255,.4)}

}

.container-fluid > .navbar-buttons,.container > .navbar-buttons{padding-right:0!important}

@media only screen and (max-height:400px){.navbar-fixed-top .navbar-collapse{max-height:280px}}

@media only screen and (max-height:360px){.navbar-fixed-top .navbar-collapse{max-height:240px}}

@media only screen and (max-height:320px){.navbar-fixed-top .navbar-collapse{max-height:200px}}

@media only screen and (max-height:280px){.navbar-fixed-top .navbar-collapse{max-height:160px}}

@media only screen and (max-height:240px){.navbar-fixed-top .navbar-collapse{max-height:120px}}

.navbar .navbar-nav > li{border:1px solid rgba(0,0,0,.2); border-width:0 1px 0 0}

.navbar .navbar-nav > li:first-child{border-width:0 1px}

.navbar .navbar-nav > li > a{padding-top:12px; padding-bottom:11px; height:45px; background-color:transparent; font-size:13px; color:#FFF; text-shadow:0 1px 0 rgba(0,0,0,.25)}

.navbar .navbar-nav > li.open > a,.navbar .navbar-nav > li > a:focus,.navbar .navbar-nav > li > a:hover{background-color:rgba(0,0,0,.1)!important; color:#FFF!important}

.navbar .navbar-nav > li > .dropdown-menu > li > a{line-height:2}

.navbar-nav .dropdown-menu > li > a > .ace-icon{display:inline-block; width:20px}

.navbar-nav > li > .dropdown-menu{z-index:1033}

@media only screen and (max-width:991px){.navbar .navbar-nav .dropdown-menu > li > a,.navbar .navbar-nav .dropdown-menu > li > a > .ace-icon{color:#FFF!important}

.navbar.navbar-collapse .navbar-menu{float:none!important; clear:both}

.navbar.navbar-collapse .navbar-menu .dropdown-menu:after,.navbar.navbar-collapse .navbar-menu .dropdown-menu:before{display:none!important}

.navbar .navbar-nav{margin:0 -6px}

.navbar .navbar-nav > li,.navbar .navbar-nav > li:first-child{border-width:1px 0 0}

.navbar .navbar-nav .dropdown-menu{float:none; position:relative; background-color:transparent; box-shadow:none; border-width:0}

.navbar .navbar-nav .dropdown-menu > li > a:hover{background-color:rgba(0,0,0,.07)!important}

.navbar .navbar-nav .dropdown-hover > .dropdown-menu{left:32px!important; right:auto!important; top:auto!important; width:80%!important; width:calc(100% - 32px)}

}

.navbar-form.form-search{margin-top:9px; margin-bottom:0}

.navbar-form.form-search .form-group{margin-bottom:0}

.navbar-form.form-search input[type=text]{width:100px}

.navbar-form input[type=text]{font-size:13px; line-height:1.4; padding-top:4px; padding-bottom:4px; border-width:0}

@media only screen and (max-width:991px){.navbar-form .form-group{display:inline-block; width:50%; margin-bottom:0; vertical-align:middle}

.navbar-form.form-search input[type=text]{width:100%}

.navbar-nav+.navbar-form{margin-top:0}

.navbar .navbar-collapse,.navbar .navbar-form{border-color:rgba(255,255,255,.4)}

.navbar .navbar-nav > li:first-child{border-top-width:0}

}

@media only screen and (max-width:480px){.navbar-form .form-group{width:80%}}

.navbar-fixed-bottom .navbar-collapse,.navbar-fixed-top .navbar-collapse,.navbar-static-top .navbar-collapse{padding-left:12px; padding-right:12px}

@media only screen and (min-width:768px) and (max-width:991px){.navbar.navbar-collapse{background-color:transparent}

.navbar.navbar-collapse:before{background:#438EB9; content:""; display:block; position:absolute; min-height:inherit; left:0; right:0; top:0; z-index:-1; border-radius:inherit}

.navbar.navbar-collapse .navbar-container{background-color:#438EB9}}

.navbar .navbar-toggle{border:1px solid transparent; width:40px; height:35px; line-height:0; padding:0 0 1px; text-align:center}

.navbar .navbar-toggle.pull-right{margin:5px 6px 4px 0}

.navbar .navbar-toggle.pull-left{margin:5px 0 4px 6px}

.navbar .navbar-toggle:active{top:1px}

.navbar .navbar-toggle.navbar-toggle-img{width:auto; height:auto; overflow:hidden; padding:0; border-width:0}

.navbar .navbar-toggle.navbar-toggle-img > img{border-radius:inherit; max-height:35px; border-width:0}

.navbar .navbar-toggle .icon-bar{background-color:#FFF; border-radius:0; width:24px; margin-left:7px}

.sidebar{width:190px; float:left; position:static; padding-left:0; padding-right:0}

.sidebar:before{display:block; width:inherit; position:absolute; top:0; bottom:0; z-index:-1; background-color:inherit; border-style:inherit; border-color:inherit; border-width:inherit}

.nav-list{margin:0; padding:0; list-style:none}

.nav-list .nav-header,.nav-list > li,.nav-list > li > a{margin:0}

.nav-list > li {display:block; position:relative; float:none; padding:0; border-bottom:1px solid #373737; /* border-style:solid; border-width:1px 0 0*/ }

.nav-list > li:last-child {border-bottom-width:1px}

.nav-list > li > a { display:block;  line-height:21px; padding-left:7px;  text-shadow:none!important; font-size:1.4rem;  color:#bdbdbd; text-transform:capitalize;  }


.nav-list > li.main-nav { display:block;  line-height:21px; text-shadow:none!important; font-size:1.5rem;  color:#fff; text-transform:capitalize;  }

.nav-list > li.main-nav .menu-text1 { color:#fff; text-transform:capitalize; margin-right:25px; }


.nav-list > li.active > a, .nav-list li > a:focus, .nav-list > li > a:hover { color:#fff; background:#080808 url(/assets/images/nav-line.jpg) repeat-y left top; -webkit-transition:background 0.6s; -moz-transition:background 0.6s;  -o-transition:background 0.6s;  transition:background 0.6s; }

/*
.nav-list > li > a > .menu-icon { display:inline-block; min-width:30px;  margin-right:2px;  vertical-align:sub;  text-align:center;  font-size:18px;  font-weight:400; }
*/

.nav-list > li.active > a, .nav-list > li > a:hover { color:#fff; background:#080808 url(/assets/images/nav-line.jpg) repeat-y left top; -webkit-transition:background 0.6s; -moz-transition:background 0.6s;  -o-transition:background 0.6s;  transition:background 0.6s; }

.nav-list > li > a > dd { display:inline-block; min-width:23px; margin:0 7px 0 7px;  vertical-align:sub;  text-align:center;  font-size:18px;  font-weight:400;  }

.nav-list > li > a > dd > img { opacity: 0.7; filter: alpha(opacity=70); /* For IE8 and earlier */ }

.nav-list > li.active > a img, .nav-list > li > a:hover img { opacity: 1; filter: alpha(opacity=100); /* For IE8 and earlier */  -webkit-transition:opacity 0.6s; -moz-transition:opacity 0.6s;  -o-transition:opacity 0.6s;  transition:opacity 0.6s;  }

/*
.nav-list > li:before { display:none; position:absolute; top:-1px; left:0; z-index:1; height:47px; width:3px; max-width:3px; overflow:hidden; background:#629CC9; -webkit-transition:background 0.6s; -moz-transition:background 0.6s;  -o-transition:background 0.6s;  transition:background 0.6s; }
*/

@media only screen and (min-width:992px){.main-container.container .sidebar:not(.h-sidebar) .nav-list > li:before{left:-2px}

}

.main-container.container .sidebar.sidebar-fixed:not(.h-sidebar) .nav-list > li:before{left:0}

.nav-list > li:hover:before{display:block}

.nav-list > li a > .arrow{display:block; width:14px!important; height:14px; line-height:14px; text-shadow:none; font-size:18px; position:absolute; right:10px; top:12px; padding:0; text-align:center}

.nav-list > li.separator{height:3px; background-color:transparent; position:static; margin:1px 0; -webkit-box-shadow:none; box-shadow:none}

.nav-list > li .submenu{display:none; list-style:none; margin:0; padding:0; line-height:1.5; position:relative; }

.nav-list > li .submenu > li{margin-left:0; position:relative; background:#232323; border-left:1px dotted #373737;  }

.nav-list > li .submenu > li > a { color:#838383; display:block; position:relative; padding:9px 0 9px 30px; margin:0; border-top:1px dotted #373737; }

.nav-list > li .submenu > li > a:hover { background:#080808; color:#fff; }

.nav-list > li .submenu > li a > .menu-icon{display:none; font-size:12px; font-weight:400; width:18px; height:auto; line-height:12px; text-align:center; position:absolute; left:10px; top:15px; z-index:1; background-color:inherit}

.nav-list > li .submenu > li.active > a > .menu-icon,.nav-list > li .submenu > li:hover > a > .menu-icon{display:inline-block; }

.nav-list > li > .submenu{border-top:1px solid}

.nav-list > li > .submenu > li:before{display:block; width:7px; position:absolute; z-index:1; left:20px; top:17px; border:1px dotted; border-width:1px 0 0}

.nav-list > li > .submenu:before{display:block; position:absolute; z-index:1; left:18px; top:0; bottom:0; border-left:1px dotted #373737;  }

.nav-list li.active > a > .arrow,.nav-list li.open > a > .arrow,.nav-list li:hover > a > .arrow{color:inherit}

.nav-list li.open > .submenu,.nav-list > li .submenu.collapsing,.nav-list > li > .submenu li.open > .submenu{display:block}

.nav-list > li > a > .menu-icon,no_such_element:-o-prefocus{vertical-align:middle}

.nav-list > li > .submenu li > .submenu{display:none}

.nav-list > li > .submenu li.active > .submenu,.nav-list > li > .submenu li > .submenu.collapsing{display:block}

.nav-list > li > .submenu a > .arrow{right:10px; top:10px; font-size:16px}

.nav-list > li > .submenu > li:first-child > a {border-top-width:0}

.nav-list > li > .submenu li > .submenu > li{line-height:18px}

.nav-list > li > .submenu li > .submenu > li:before{display:none}

.nav-list > li > .submenu li > .submenu > li > a{margin-left:20px; padding-left:22px}

.nav-list > li > .submenu li > .submenu > li > .submenu > li > a { margin-left:20px; padding-left:38px}

.nav-list > li > .submenu li > .submenu > li a > .menu-icon{display:inline-block; width:auto; position:static; background-color:transparent; margin-right:4px; color:inherit}

.nav-list > li > .submenu li > .submenu > li a .arrow,.nav-list > li > .submenu li > .submenu > li a .menu-icon{color:inherit}

.nav-list > li > .submenu li.open:not(.hover) > a > .menu-icon{display:inline-block}

.nav-list a .badge,.nav-list a .label { position:absolute; top:9px; right:10px; opacity:.88; font-size:12px; padding-left:6px; padding-right:6px;  }

.nav-list a .badge .ace-icon,.nav-list a .label .ace-icon{vertical-align:middle; margin:0}

.nav-list a.dropdown-toggle .badge,.nav-list a.dropdown-toggle .label{right:26px}

.nav-list li:hover > a .badge,.nav-list li:hover > a .label{opacity:1}

.nav-list .submenu .submenu a .badge,.nav-list .submenu .submenu a .label{top:6px}

.sidebar.sidebar-fixed{position:fixed; top:auto; float:none!important; z-index:1027}

.sidebar.sidebar-fixed:before{height:5000px; top:auto; bottom:auto}

.sidebar-scroll-native{overflow-y:scroll!important; overflow-x:hidden}

body.mob-safari .sidebar.sidebar-fixed{top:45px}

@media (max-width:479px){body.mob-safari .navbar-fixed-top:not(.navbar-collapse)+.main-container .sidebar-fixed{top:90px}

}

@media (max-width:991px){body.mob-safari .navbar-fixed-top+.main-container .sidebar.responsive{top:45px}

}

@media (max-width:479px){body.mob-safari .navbar-fixed-top+.main-container .sidebar.responsive{top:90px}

}

@media only screen and (max-width:991px){.responsive.sidebar-fixed{left:auto; margin-left:auto}

.navbar-fixed-top+.main-container > .responsive{position:fixed; left:auto; margin-left:auto}

.navbar-fixed-top+.main-container > .menu-toggler+.responsive{position:absolute; left:190px; margin-left:-190px}

.navbar-fixed-top+.main-container > .menu-toggler+.responsive.sidebar-fixed,.sidebar.responsive.sidebar-fixed,.sidebar.sidebar-fixed.responsive-max{position:fixed; left:auto; margin-left:auto}

.main-container .menu-toggler.fixed{position:fixed; left:auto; z-index:1026}

}

.sidebar .sidebar-toggle{border-style:solid; border-width:0 0 0px; text-align:center; padding:15px 0; position:relative}

.sidebar .sidebar-toggle > .ace-icon{padding:0 5px; line-height:18px; cursor:pointer; font-size:21px; color:#fff; position:relative}

.sidebar .sidebar-toggle:before{display:block; height:0; position:absolute; left:15px; right:15px; top:15px}

.sidebar .sidebar-shortcuts{text-align:center; min-height:40px; margin-bottom:0; overflow:hidden; position:relative; border:0 solid}

.sidebar .sidebar-shortcuts:empty{display:none}

.sidebar .sidebar-shortcuts-large{line-height:37px}

.sidebar .sidebar-shortcuts-large > .btn{text-align:center; width:41px; line-height:24px; padding:0; border-width:4px}

.sidebar .sidebar-shortcuts-large > .btn > .ace-icon{margin:0}

.sidebar .sidebar-shortcuts-mini{display:none; font-size:0; width:42px; padding-top:2px; padding-bottom:2px}

.sidebar .sidebar-shortcuts-mini > .btn{border-width:0; font-size:0; line-height:0; padding:8px; margin:1px; border-radius:0; opacity:.85; filter:alpha(opacity=85)}

@media screen and (-webkit-min-device-pixel-ratio:0){.sidebar .sidebar-shortcuts-mini > .btn,.sidebar ::safari-only{margin:0}

}

.nav-list li.active > a:after{display:block; position:absolute; right:0; top:4px; border:8px solid transparent; border-width:14px 10px}

.nav-list li.open > a:after{display:none}

.nav-list li.active.no-active-child > a:after{display:block}

.nav-list > li li.active > a:after{top:2px; border-width:14px 8px}

.nav-list li.active:not(.open) li.active > a:after{display:none!important}

@media (min-width:992px){.sidebar.compact,.sidebar.compact.navbar-collapse{width:105px}

.sidebar.compact .sidebar-shortcuts{max-height:40px}

.sidebar.compact .nav-list > li:before{height:auto; bottom:-1px}

.sidebar.compact .nav-list > li > a{height:auto; line-height:16px; padding:2px 0 8px; text-align:center}

.sidebar.compact .nav-list > li > a > .menu-icon{display:block; margin:0; vertical-align:inherit; line-height:32px; height:32px; font-size:20px}

.sidebar.compact .nav-list > li.active > .submenu,.sidebar.compact .nav-list > li.open > .submenu,.sidebar.compact .nav-list > li > a > .arrow{display:none}

.sidebar.compact .nav-list a .badge,.sidebar.compact .nav-list a .label{right:12px}

.sidebar.compact+.main-content{margin-left:105px}

}

@media (min-width:992px) and (max-width:991px){.sidebar.compact+.main-content{margin-left:0}

.sidebar.navbar-collapse.compact .nav-list > li > a{text-align:left; height:45px; line-height:37px; padding:0 16px 0 7px}

.sidebar.navbar-collapse.compact .nav-list > li > a > .menu-icon{display:inline-block; vertical-align:sub; height:auto; line-height:inherit; margin:0 2px 0 0; font-size:18px}

.sidebar.navbar-collapse.compact .nav-list > li > a > .arrow,.sidebar.navbar-collapse.compact .nav-list > li > a > .menu-text{display:inline}

.sidebar.navbar-collapse.compact+.main-content{margin-left:0}

}

@media only screen and (min-width:992px){.nav-list li.hover > .submenu{position:absolute; left:100%; margin-left:-2px; top:-10px; bottom:auto; z-index:1026; width:190px; border:1px solid; display:none!important; padding:0 2px}

.nav-list li.hover.hover-show > .submenu,.nav-list li.hover:hover > .submenu{display:block!important}

.nav-list li.hover > .submenu:before,.nav-list li.hover > .submenu > li:before{display:none}

.nav-list li.hover.active > a:after{display:block}

.nav-list li.hover.active.hover-show > a.dropdown-toggle:after,.nav-list li.hover.active:hover > a.dropdown-toggle:after{display:none; }

.nav-list li.hover .submenu > li.active > a:after{display:none!important}

.nav-list li.hover > .submenu.bottom{top:auto; bottom:-10px}

.nav-list li.hover > .submenu.bottom:after,.nav-list li.hover > .submenu.bottom:before{top:auto; bottom:18px}

.nav-list li.hover > .submenu > li > a{margin-top:2px; margin-bottom:2px; padding-left:18px; border-top-width:0}

.nav-list > li.hover > .submenu > li.active > a{font-weight:700}

.nav-list > li > .submenu li.hover > .submenu > li > a{padding-left:12px!important; margin-left:auto!important}

.nav-list > li > .submenu li.hover > .submenu > li > a > .menu-icon{margin-right:2px}

.nav-list > li .submenu > li.hover > a{padding-left:22px}

.nav-list > li .submenu > li.hover > a > .menu-icon{left:4px}

}

@media (min-width:992px) and (max-width:991px){.sidebar.navbar-collapse .nav-list li.hover > .submenu{position:relative; left:auto; top:auto; z-index:auto; margin:auto; padding:0; width:auto; -webkit-box-shadow:none; box-shadow:none; border-width:0}

.sidebar.navbar-collapse .nav-list li.hover > .submenu:before,.sidebar.navbar-collapse .nav-list li.hover > .submenu > li:before{display:block}

.sidebar.navbar-collapse .nav-list li.hover > .submenu li > a{padding-left:37px}

.sidebar.navbar-collapse .nav-list > li.hover > .submenu{border-top-width:1px}

.sidebar.navbar-collapse .nav-list li.hover > .submenu > li > a > .menu-icon{background-color:inherit; margin-right:auto; width:18px; position:absolute}

.sidebar.navbar-collapse .nav-list > li .submenu > li.hover > a > .menu-icon{left:10px}

.sidebar.navbar-collapse .nav-list li.hover > .submenu > li.active > a{font-weight:400}

.sidebar.navbar-collapse .nav-list li.hover > .submenu > li.active > a > .menu-icon,.sidebar.navbar-collapse .nav-list li.hover > .submenu > li.hover-show > a > .menu-icon,.sidebar.navbar-collapse .nav-list li.hover > .submenu > li:hover > a > .menu-icon{display:inline-block}

.sidebar.navbar-collapse .nav-list > li > .submenu li.hover > .submenu{padding-left:0; padding-right:0}

.sidebar.navbar-collapse .nav-list > li > .submenu li.hover > .submenu > li > a{padding-left:22px!important; margin-left:20px!important}

.sidebar.navbar-collapse .nav-list > li > .submenu li.hover > .submenu > li > a > .menu-icon{display:inline-block; width:auto; position:static; background-color:transparent; margin-right:4px}

.sidebar.navbar-collapse .nav-list > li > .submenu li > .submenu > li.hover > .submenu > li > a{margin-left:20px!important; padding-left:38px!important}

.sidebar.navbar-collapse .nav-list li.hover > .submenu > li > a{border-top-width:1px; margin-top:0; margin-bottom:0}

.sidebar.navbar-collapse .nav-list > li.hover > .submenu > li:first-child > a{border-top-width:0}

.sidebar.navbar-collapse .nav-list li.hover.hover-show > .submenu,.sidebar.navbar-collapse .nav-list li.hover:hover > .submenu,.sidebar.navbar-collapse .nav-list li.hover > .arrow,.sidebar.navbar-collapse .nav-list li.hover > .submenu{display:none!important}

.sidebar.navbar-collapse .nav-list li.hover.active > .submenu{display:block!important}

.sidebar.navbar-collapse .nav-list li.hover > .submenu.nav-hide{display:none!important}

.sidebar.navbar-collapse .nav-list li.hover > .submenu.nav-show{display:block!important}

.sidebar.navbar-collapse.compact .nav-list > li.hover.active > a:after{display:none}

}

.nav-list li > .arrow{display:none; position:absolute; top:8px; right:1px; z-index:1027}

.nav-list li > .arrow:after,.nav-list li > .arrow:before{border:1px solid transparent; display:block; height:0; width:0; position:absolute; left:-19px; top:0; right:auto; bottom:auto; content:""; border-width:10px}

.nav-list li > .arrow:before{z-index:1; left:-20px}

.nav-list li > .arrow:after{z-index:2}

.nav-list li.hover-show > a+.arrow,.nav-list li:hover > a+.arrow{display:none}

@media only screen and (min-width:992px){.nav-list li.hover.hover-show > a.dropdown-toggle+.arrow,.nav-list li.hover:hover > a.dropdown-toggle+.arrow{display:block; }

}

.sidebar.menu-min,.sidebar.menu-min.compact,.sidebar.menu-min.navbar-collapse{width:43px}

.sidebar.menu-min+.main-content{margin-left:43px}

.sidebar.menu-min .nav-list a .badge,.sidebar.menu-min .nav-list a .label{position:relative; top:-1px; right:auto; left:4px}

.sidebar.menu-min .nav-list .submenu .submenu a .badge,.sidebar.menu-min .nav-list .submenu .submenu a .label{top:0}

.sidebar.menu-min .nav-list > li.main-nav {display:block; color:#fff; -webkit-transition:background 0.6s; -moz-transition:background 0.6s;  -o-transition:background 0.6s;  transition:background 0.6s; }

.sidebar.menu-min .nav-list > li.main-nav .menu-text1 {display:none; }

.sidebar.menu-min .nav-list > li > a > .menu-text { display:none; text-align:left; position:absolute; top:-1px; left:43px; z-index:1025; width:176px; height:47px; line-height:45px; padding-left:12px; }

.sidebar.menu-min .nav-list > li > a { position:relative; height:45px; line-height:37px; padding:0; }

.sidebar.menu-min .nav-list > li > a > .menu-icon{font-size:18px; height:auto; line-height:inherit; display:inline-block; vertical-align:sub; margin-right:0; width:44px; }

.sidebar.menu-min .nav-list > li > a.dropdown-toggle > .menu-text{top:-1px; border-width:1px 1px 0; }

.sidebar.menu-min .nav-list > li > a .arrow{display:none}

.sidebar.menu-min .nav-list > li.active.open > a:after,.sidebar.menu-min .nav-list > li.hover-show > a > .menu-text,.sidebar.menu-min .nav-list > li:hover > a > .menu-text{display:block;  color:#fff; background:#080808 url(/assets/images/nav-line.jpg) repeat-y left top; -webkit-transition:background 0.6s; -moz-transition:background 0.6s;  -o-transition:background 0.6s;  transition:background 0.6s; }

.sidebar.menu-min .nav-list > li.active > a:after{border-width:10px 6px; top:8px}

.sidebar.menu-min .nav-list > li.active.open li.active > a:after{display:none}

.sidebar.menu-min .nav-list > li > .submenu{position:absolute; z-index:1026; left:43px; top:-1px; margin-top:40px; padding-bottom:2px; width:176px; display:none!important; height:auto!important; visibility:visible!important}

.sidebar.menu-min .nav-list > li > .submenu:before{display:none}

.sidebar.menu-min .nav-list > li > .submenu:after{display:block; height:1px; width:1px; position:absolute; right:-1px; top:-1px; border-right:1px solid; border-right-color:inherit}

.sidebar.menu-min .nav-list > li.open > .submenu,.sidebar.menu-min .nav-list > li > .submenu li:before{display:none}

.sidebar.menu-min .nav-list > li > .submenu li > a{margin-left:0; padding-left:22px}

.sidebar.menu-min .nav-list > li > .submenu li > a > .menu-icon{left:4px}

.sidebar.menu-min .nav-list > li.hover > .submenu{padding-bottom:0}

.sidebar.menu-min .nav-list > li.hover-show > .submenu,.sidebar.menu-min .nav-list > li:hover > .submenu{display:block!important}

.sidebar.menu-min .nav-list > li.hover-show > .arrow,.sidebar.menu-min .nav-list > li:hover > .arrow,.sidebar.menu-min .sidebar-shortcuts-mini{display:block}

.sidebar.menu-min .nav-list > li > .arrow{top:10px}

.sidebar.menu-min .nav-list > li > .arrow:after,.sidebar.menu-min .nav-list > li > .arrow:before{border-width:8px; left:-16px}

.sidebar.menu-min .nav-list > li > .arrow:before{left:-17px}

.sidebar.menu-min .nav-list li > .arrow{right:-1px}

.sidebar.menu-min .sidebar-shortcuts{position:relative; overflow:visible; z-index:1}

.sidebar.menu-min .sidebar-shortcuts-large{display:none; position:absolute; top:0; left:43px; width:182px; padding:0 2px 3px; border:1px solid; border-top-width:0}

.sidebar.menu-min .sidebar-shortcuts.hover-show .sidebar-shortcuts-large,.sidebar.menu-min .sidebar-shortcuts:hover .sidebar-shortcuts-large{display:block}

.sidebar.menu-min .sidebar-toggle:before{left:5px; right:5px}

.sidebar.menu-min .sidebar-toggle > .ace-icon{font-size:21px; padding:0 4px; line-height:15px; border-width:1px}

.sidebar.menu-min .nav-list > li > .submenu li > .submenu > li > a{margin-left:2px; padding-left:30px}

.sidebar.menu-min .nav-list > li > .submenu li > .submenu > li > .submenu > li > a{margin-left:2px; padding-left:45px}

.sidebar.menu-min .nav-list li.active.open > .submenu > li.active > a:after,.sidebar.menu-min .nav-list > li.active.hover-show > a:after,.sidebar.menu-min .nav-list > li.active.open:hover > a:after,.sidebar.menu-min .nav-list > li.active:hover:after,.sidebar.menu-min .nav-list > li.active:hover > a:after,.sidebar.menu-min .nav-list > li > .submenu li.active > a:after{display:none}

@media (min-width:992px){.sidebar.menu-min .nav-list li.hover > .submenu{margin-left:0}

.sidebar.menu-min .nav-list > li > .submenu li.hover > .submenu > li > a,.sidebar.menu-min .nav-list > li > .submenu li > .submenu > li.hover > .submenu > li > a{margin-left:0!important}

.sidebar.menu-min.compact .nav-list > li > a{height:45px; line-height:37px; padding:0 16px 0 7px; position:relative}

.sidebar.menu-min.compact .nav-list > li > a > .menu-icon{font-size:18px; height:auto; line-height:inherit; display:inline-block; vertical-align:sub; width:auto}

}

@media only screen and (min-width:992px){.navbar.h-navbar{-webkit-box-shadow:0 2px 4px 0 rgba(0,0,0,.25); box-shadow:0 2px 4px 0 rgba(0,0,0,.25)}

.sidebar.h-sidebar{position:relative; float:none!important; width:auto; margin-top:17px; border-width:0!important; box-shadow:none}

.sidebar.h-sidebar.compact,.sidebar.h-sidebar.menu-min{width:auto}

.sidebar.h-sidebar.sidebar-fixed{position:fixed}

.sidebar.h-sidebar.no-gap{margin-top:auto}

.sidebar.h-sidebar.no-gap .nav-list{padding-top:3px}

.sidebar.h-sidebar:before{display:block; position:absolute; z-index:-1; left:0!important; right:0!important; top:-17px; bottom:auto; height:17px; width:auto; border-width:0; border-bottom:1px solid; border-bottom-color:inherit}

.main-content .sidebar.h-sidebar .nav-list > li:before,.sidebar.h-sidebar.lower-highlight .nav-list > li:before{top:0}

.sidebar.h-sidebar .nav-list{border-width:0; border-bottom:2px solid #79B0CE}

.sidebar.h-sidebar .nav-list > li{float:left; border-width:0 0 0 1px; margin-left:0}

.sidebar.h-sidebar .nav-list > li:last-child{border-right-width:1px}

.sidebar.h-sidebar .nav-list > li:before{left:-1px; right:-1px; bottom:auto; top:-2px; max-width:none; width:auto; height:2px; border-width:0}

.sidebar.h-sidebar .nav-list > li.active:before{display:block}

.sidebar.h-sidebar .nav-list > li.active:after{display:none}

.sidebar.h-sidebar .nav-list > li:first-child{border-left-width:0; margin-left:0}

.sidebar.h-sidebar .sidebar-shortcuts+.nav-list > li:first-child{border-left-width:1px; margin-left:-1px}

.sidebar.h-sidebar .nav-list > li > a{line-height:22px; height:auto; padding:10px 14px}

.sidebar.h-sidebar .nav-list > li > a > .menu-icon{display:block; margin:1px 0 4px; line-height:inherit; width:auto}

.sidebar.h-sidebar .nav-list > li.hover.hover-show > a~.arrow,.sidebar.h-sidebar .nav-list > li.hover:hover > a~.arrow,.sidebar.h-sidebar .nav-list > li > a > .arrow{display:none}

.sidebar.h-sidebar .nav-list > li.hover.pull_right{float:none!important}

.sidebar.h-sidebar .nav-list > li.hover > .submenu{top:100%; left:1px; margin-top:auto; margin-left:-2px; width:190px; z-index:1026}

.sidebar.h-sidebar .nav-list > li.hover.pull_right > .submenu{left:auto; right:1px}

.sidebar.h-sidebar .nav-list > li.hover > .arrow{right:auto; top:auto; left:24px; bottom:7px}

.sidebar.h-sidebar .nav-list > li.hover.pull_right > .arrow{left:auto; right:24px}

.sidebar.h-sidebar .nav-list > li.hover > .arrow:after,.sidebar.h-sidebar .nav-list > li.hover > .arrow:before{border-color:transparent; border-left-color:transparent!important; border-right-color:transparent!important; right:auto!important; left:-10px!important; border-width:0 8px 8px!important}

.sidebar.h-sidebar .nav-list > li.hover > .arrow:before{top:-1px!important}

.sidebar.h-sidebar .nav-list > li.hover.hover-show > a.dropdown-toggle~.arrow,.sidebar.h-sidebar .nav-list > li.hover:hover > a.dropdown-toggle~.arrow{display:block; }

.sidebar.h-sidebar .nav-list > li.hover-flip > .submenu{left:auto; right:1px; margin-left:auto; margin-right:-2px}

.sidebar.h-sidebar .nav-list > li.hover-flip > .arrow{left:auto; right:24px}

.sidebar.h-sidebar .nav-list li li.hover-flip > .submenu{left:auto; right:100%; margin-left:auto; margin-right:-2px}

.sidebar.h-sidebar .nav-list li li.hover-flip > .arrow{filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1); -moz-transform:scale(-1,1); -webkit-transform:scale(-1,1); -o-transform:scale(-1,1); -ms-transform:scale(-1,1); transform:scale(-1,1); left:0; right:auto}

.sidebar.h-sidebar .sidebar-shortcuts-large:after,.sidebar.h-sidebar .sidebar-shortcuts-large:before{display:block; content:""; position:absolute; left:50%; margin-left:-8px; border:1px solid transparent; border-width:0 8px 8px}

.sidebar.h-sidebar .sidebar-shortcuts-large:after{border-right-color:transparent; top:-8px}

.sidebar.h-sidebar .sidebar-shortcuts-large:before{border-bottom-color:#CCC; top:-9px}

.sidebar.h-sidebar .sidebar-toggle{position:absolute; top:12px; right:6px; background-color:transparent; border-width:0}

.sidebar.h-sidebar .sidebar-shortcuts{float:left; display:block; overflow:visible; position:relative; margin:0; padding:12px 8px 9px; max-height:none!important; background-color:transparent}

.sidebar.h-sidebar .sidebar-shortcuts.hover-show .sidebar-shortcuts-large,.sidebar.h-sidebar .sidebar-shortcuts:hover .sidebar-shortcuts-large{display:block}

.sidebar.h-sidebar .sidebar-shortcuts-large{display:none; width:52px; height:auto; position:absolute; z-index:1025; top:100%; margin-top:-5px; left:10px; padding:3px 2px; border:1px solid}

.sidebar.h-sidebar .sidebar-shortcuts-mini{width:auto; max-width:52px; display:block; background-color:transparent; padding-top:3px; padding-bottom:5px}

.sidebar.h-sidebar .sidebar-shortcuts-mini > .btn{padding:9px}

.sidebar.h-sidebar .nav-list a .badge,.sidebar.h-sidebar .nav-list a .label{position:absolute; top:11px; right:auto!important; left:50%; margin-left:10px}

.sidebar.h-sidebar+.main-content{margin-left:0}

.sidebar.h-sidebar+.main-content .breadcrumbs{margin:6px 9px; border:1px solid #E5E5E5}

.h-sidebar.sidebar-fixed{top:auto; width:100%; left:0; right:0}

.h-sidebar.sidebar-fixed:before{z-index:auto}

.h-sidebar.sidebar-fixed,.h-sidebar.sidebar-fixed .nav-list{border-right-width:0; border-left-width:0}

.h-sidebar.sidebar-fixed .sidebar-shortcuts{border-width:0}

.h-sidebar.sidebar-fixed+.main-content{padding-top:88px}

.h-sidebar.sidebar-fixed.no-gap+.main-content{padding-top:74px}

.h-sidebar.sidebar-fixed.menu-min+.main-content{padding-top:63px}

.h-sidebar.sidebar-fixed.menu-min.no-gap+.main-content{padding-top:49px}

}

@media only screen and (min-width:992px) and (max-width:991px){.sidebar.h-sidebar+.main-content .breadcrumbs{margin:0; border-width:0 0 1px}

}

.main-container.container .h-sidebar.sidebar-fixed{left:0; right:0; width:auto}

@media (min-width:768px){.main-container.container .h-sidebar.sidebar-fixed{left:auto!important; right:auto!important; width:744px}

}

@media (min-width:992px){.main-container.container .h-sidebar.sidebar-fixed{width:964px}

}

@media (min-width:1200px){.main-container.container .h-sidebar.sidebar-fixed{width:1164px}

}

@media (min-width:992px){.sidebar.h-sidebar.menu-min .nav.nav-list > li > a,.sidebar.h-sidebar.menu-min.compact .nav.nav-list > li > a{padding:9px 12px}

.sidebar.h-sidebar.menu-min .nav-list > li > a > .menu-text{left:-1px; top:100%; width:190px}

.sidebar.h-sidebar.menu-min .nav-list > li > a > .menu-icon{margin:2px 0}

.sidebar.h-sidebar.menu-min .nav-list > li.hover > .submenu{top:100%; margin-top:41px}

.sidebar.h-sidebar.menu-min .nav-list > li.hover.hover-show > a~.arrow,.sidebar.h-sidebar.menu-min .nav-list > li.hover:hover > a~.arrow{display:block}

.sidebar.h-sidebar.menu-min .sidebar-toggle{top:6px}

.sidebar.h-sidebar.menu-min .sidebar-shortcuts{padding:0}

.sidebar.h-sidebar.menu-min .sidebar-shortcuts-mini{padding-bottom:4px; padding-top:4px}

.sidebar.h-sidebar.menu-min .sidebar-shortcuts-mini > .btn{padding:8px}

.sidebar.h-sidebar.menu-min .sidebar-shortcuts-large{left:1px; margin-top:4px}

.sidebar.h-sidebar.compact .nav-list > li > a > .menu-icon{font-size:18px; height:auto}

.sidebar.h-sidebar.compact.menu-min .nav-list > li > a{height:auto; line-height:22px}

}

.main-container .menu-toggler{display:none}

@media only screen and (max-width:991px){.sidebar.responsive:before{display:none}

.sidebar.responsive{-webkit-box-shadow:2px 1px 2px 0 rgba(0,0,0,.15); box-shadow:2px 1px 2px 0 rgba(0,0,0,.15); z-index:1027; overflow:hidden; max-height:0; -moz-transform:translateX(-200px); -webkit-transform:translateX(-200px); -o-transform:translateX(-200px); -ms-transform:translateX(-200px); transform:translateX(-200px); -webkit-transition:-webkit-transform .2s linear 0s,max-height 0s linear .2s; -moz-transition:-moz-transform .2s linear 0s,max-height 0s linear .2s; -o-transition:-o-transform .2s linear 0s,max-height 0s linear .2s; transition:transform .2s linear 0s,max-height 0s linear .2s; position:relative; bottom:auto; left:190px; margin-left:-190px}

}

@media (min-width:992px) and (max-width:991px){.sidebar.responsive.compact{-moz-transform:translateX(-115px); -webkit-transform:translateX(-115px); -o-transform:translateX(-115px); -ms-transform:translateX(-115px); transform:translateX(-115px)}

.navbar.navbar-fixed-top+.main-container .sidebar.responsive.compact.push_away.display~.footer .footer-inner,.sidebar.responsive.compact.push_away.display+.main-content{-moz-transform:translateX(105px); -webkit-transform:translateX(105px); -o-transform:translateX(105px); -ms-transform:translateX(105px); transform:translateX(105px)}

}

@media only screen and (max-width:991px){.sidebar.responsive.menu-min{-moz-transform:translateX(-53px); -webkit-transform:translateX(-53px); -o-transform:translateX(-53px); -ms-transform:translateX(-53px); transform:translateX(-53px)}

.sidebar.responsive.display{-moz-transform:none!important; -webkit-transform:none!important; -o-transform:none!important; -ms-transform:none!important; transform:none!important; overflow:visible; max-height:2000px; -webkit-transition-delay:0s; -moz-transition-delay:0s; -o-transition-delay:0s; transition-delay:0s}

.sidebar.responsive.push_away{-webkit-box-shadow:none!important; box-shadow:none!important}

.sidebar.responsive.push_away:before{display:block!important}

.sidebar.responsive.push_away+.main-content{-webkit-transition:-webkit-transform .2s linear 0s; -moz-transition:-moz-transform .2s linear 0s; -o-transition:-o-transform .2s linear 0s; transition:transform .2s linear 0s}

.sidebar.responsive.push_away.display+.main-content{-moz-transform:translateX(190px); -webkit-transform:translateX(190px); -o-transform:translateX(190px); -ms-transform:translateX(190px); transform:translateX(190px)}

.sidebar.responsive.push_away.display.menu-min+.main-content{-moz-transform:translateX(43px); -webkit-transform:translateX(43px); -o-transform:translateX(43px); -ms-transform:translateX(43px); transform:translateX(43px)}

.navbar.navbar-fixed-top+.main-container .sidebar.responsive.push_away:before{height:5000px}

.navbar.navbar-fixed-top+.main-container .sidebar.responsive.push_away~.footer .footer-inner{-webkit-transition:-webkit-transform .2s linear 0s; -moz-transition:-moz-transform .2s linear 0s; -o-transition:-o-transform .2s linear 0s; transition:transform .2s linear 0s}

.navbar.navbar-fixed-top+.main-container .sidebar.responsive.push_away.display~.footer .footer-inner{-moz-transform:translateX(190px); -webkit-transform:translateX(190px); -o-transform:translateX(190px); -ms-transform:translateX(190px); transform:translateX(190px)}

.navbar.navbar-fixed-top+.main-container .sidebar.responsive.push_away.display.menu-min~.footer .footer-inner{-moz-transform:translateX(43px); -webkit-transform:translateX(43px); -o-transform:translateX(43px); -ms-transform:translateX(43px); transform:translateX(43px)}

.sidebar.responsive+.main-content{margin-left:0!important; margin-right:0!important}

.responsive.sidebar-fixed{position:fixed}

.main-container .menu-toggler.fixed{position:fixed; left:auto; z-index:1026; -moz-backface-visibility:hidden}

}

@media only screen and (min-width:768px) and (max-width:991px){.main-container.container .sidebar.responsive{-webkit-transition:none; -moz-transition:none; -o-transition:none; transition:none; display:none}

.main-container.container .sidebar.responsive:before{display:none!important}

.main-container.container .sidebar.responsive.display{display:block}

.main-container.container .sidebar.responsive.push_away+.main-content,.main-container.container .sidebar.responsive.push_away~.footer .footer-inner{-webkit-transition:none!important; -moz-transition:none!important; -o-transition:none!important; transition:none!important; -moz-transform:none!important; -webkit-transform:none!important; -o-transform:none!important; -ms-transform:none!important; transform:none!important}

}

@media only screen and (max-width:991px){.menu-toggler+.sidebar.responsive{margin-top:40px}

.main-container .menu-toggler{display:block; position:absolute; z-index:1026; width:52px; height:32px; margin-right:2px; line-height:normal; padding-left:33px; padding-top:7px; padding-bottom:1px; font-size:13px; font-weight:700; text-transform:uppercase; -webkit-box-sizing:content-box; -moz-box-sizing:content-box; box-sizing:content-box}

.main-container .menu-toggler:after,.main-container .menu-toggler:before{content:""; height:2px; width:24px; left:4px; position:absolute}

.main-container .menu-toggler:hover{text-decoration:none}

.main-container .menu-toggler:focus{outline:0}

.main-container .menu-toggler:before{border-top:1px solid #87B87F; border-bottom:1px solid #6FB3E0; z-index:1; top:13px; -webkit-transition:all .1s ease; transition:all .1s ease; -o-transition:none; -webkit-box-sizing:content-box; -moz-box-sizing:content-box; box-sizing:content-box}

.main-container .menu-toggler:after{border-top:1px solid #FFA24D; border-bottom:1px solid #D15B47; top:19px; -webkit-transition:all .1s ease; transition:all .1s ease; -o-transition:none; -webkit-box-sizing:content-box; -moz-box-sizing:content-box; box-sizing:content-box}

.main-container .menu-toggler.display:before{height:4px; top:8px; border-width:2px}

.main-container .menu-toggler.display:after{height:4px; top:20px; border-width:2px}

.main-container .menu-toggler > .toggler-text{display:block; position:absolute; bottom:-9px; left:0; border:1px solid transparent; border-width:9px 42px 0; border-top-color:#444}

.main-container .menu-toggler > .toggler-text:after{display:block; content:"MENU"; color:#FFF; position:absolute; left:-8px; top:-41px}

.menu-toggler+.responsive.sidebar-scroll .nav-wrap{overflow:hidden}

}

@media only screen and (max-width:320px){.main-container .menu-toggler{width:0}

.main-container .menu-toggler > .toggler-text{border-width:7px 16px; bottom:-14px}

.main-container .menu-toggler > .toggler-text:after{font-size:9px; font-weight:400; color:#FFF; position:absolute; left:-13px; top:-42px}

.main-container .menu-toggler:after,.main-container .menu-toggler:before{margin-top:8px}

.main-container .menu-toggler.display:after,.main-container .menu-toggler.display:before{height:2px; border-width:1px}

.main-container .menu-toggler.display:before{top:13px}

.main-container .menu-toggler.display:after{top:19px}

}

.sidebar-toggle.sidebar-expand{display:none}

@media (max-width:991px){.menu-toggler.invisible{position:fixed; z-index:-999; visibility:hidden; opacity:0; width:1px; height:1px}

.sidebar.responsive-min,.sidebar.responsive-min.compact,.sidebar.responsive-min.navbar-collapse{width:43px}

.sidebar.responsive-min .nav-list a .badge,.sidebar.responsive-min .nav-list a .label{position:relative; top:-1px; right:auto; left:4px}

.sidebar.responsive-min .nav-list .submenu .submenu a .badge,.sidebar.responsive-min .nav-list .submenu .submenu a .label{top:0}

.sidebar.responsive-min .nav-list > li > a{position:relative; height:45px; line-height:37px; padding:0}

.sidebar.responsive-min .nav-list > li > a > .menu-icon{font-size:18px; height:auto; line-height:inherit; display:inline-block; vertical-align:sub; margin-right:0; width:44px}

.sidebar.responsive-min .nav-list > li > a > .menu-text{display:none; text-align:left; position:absolute; top:-1px; left:43px; z-index:1025; width:176px; height:47px; line-height:38px; padding-left:12px; border:1px solid}

.sidebar.responsive-min .nav-list > li > a.dropdown-toggle > .menu-text{top:-1px; border-width:1px 1px 0; }

.sidebar.responsive-min .nav-list > li > a .arrow{display:none}

.sidebar.responsive-min .nav-list > li.active.open > a:after,.sidebar.responsive-min .nav-list > li.hover-show > a > .menu-text,.sidebar.responsive-min .nav-list > li:hover > a > .menu-text{display:block}

.sidebar.responsive-min .nav-list > li.active > a:after{border-width:10px 6px; top:8px}

.sidebar.responsive-min .nav-list > li.active.open li.active > a:after{display:none}

.sidebar.responsive-min .nav-list > li > .submenu{position:absolute; z-index:1026; left:43px; top:-1px; margin-top:40px; padding-bottom:2px; width:176px; display:none!important; height:auto!important; visibility:visible!important}

.sidebar.responsive-min .nav-list > li > .submenu:before{display:none}

.sidebar.responsive-min .nav-list > li > .submenu:after{content:""; display:block; height:1px; width:1px; position:absolute; right:-1px; top:-1px; border-right:1px solid; border-right-color:inherit}

.sidebar.responsive-min .nav-list > li.open > .submenu,.sidebar.responsive-min .nav-list > li > .submenu li:before{display:none}

.sidebar.responsive-min .nav-list > li > .submenu li > a{margin-left:0; padding-left:22px}

.sidebar.responsive-min .nav-list > li > .submenu li > a > .menu-icon{left:4px}

.sidebar.responsive-min .nav-list > li.hover > .submenu{padding-bottom:0}

.sidebar.responsive-min .nav-list > li.hover-show > .submenu,.sidebar.responsive-min .nav-list > li:hover > .submenu{display:block!important}

.sidebar.responsive-min .nav-list > li.hover-show > .arrow,.sidebar.responsive-min .nav-list > li:hover > .arrow,.sidebar.responsive-min .sidebar-shortcuts-mini{display:block}

.sidebar.responsive-min .nav-list > li > .arrow{top:10px}

.sidebar.responsive-min .nav-list > li > .arrow:after,.sidebar.responsive-min .nav-list > li > .arrow:before{border-width:8px; left:-16px}

.sidebar.responsive-min .nav-list > li > .arrow:before{left:-17px}

.sidebar.responsive-min .nav-list li > .arrow{right:-1px}

.sidebar.responsive-min .sidebar-shortcuts{position:relative; overflow:visible; z-index:1}

.sidebar.responsive-min .sidebar-shortcuts-large{display:none; position:absolute; top:0; left:43px; width:182px; padding:0 2px 3px; border:1px solid; border-top-width:0}

.sidebar.responsive-min .sidebar-shortcuts.hover-show .sidebar-shortcuts-large,.sidebar.responsive-min .sidebar-shortcuts:hover .sidebar-shortcuts-large{display:block}

.sidebar.responsive-min .sidebar-toggle:before{left:5px; right:5px}

.sidebar.responsive-min .sidebar-toggle > .ace-icon{font-size:13px; padding:0 4px; line-height:15px; border-width:1px}

.sidebar.responsive-min .nav-list > li > .submenu li > .submenu > li > a{margin-left:2px; padding-left:30px}

.sidebar.responsive-min .nav-list > li > .submenu li > .submenu > li > .submenu > li > a{margin-left:2px; padding-left:45px}

.sidebar.responsive-min .nav-list li.active.open > .submenu > li.active > a:after,.sidebar.responsive-min .nav-list > li.active.hover-show > a:after,.sidebar.responsive-min .nav-list > li.active.open:hover > a:after,.sidebar.responsive-min .nav-list > li.active:hover:after,.sidebar.responsive-min .nav-list > li.active:hover > a:after,.sidebar.responsive-min .nav-list > li > .submenu li.active > a:after{display:none}

.sidebar.responsive-min:before{display:block}

.sidebar.responsive-min .sidebar-toggle.sidebar-collapse{display:none}

.sidebar.responsive-min .sidebar-toggle.sidebar-expand{display:block}

.sidebar.responsive-min+.main-content{margin-left:43px!important}

.rtl .sidebar.responsive-min+.main-content{margin-left:auto!important; margin-right:43px!important}

.sidebar.responsive-min+.main-content .breadcrumb{margin-left:12px}

.sidebar.responsive-max{display:block; position:relative; left:190px; margin-left:-190px; z-index:1027; -webkit-box-shadow:2px 1px 2px 0 rgba(0,0,0,.15); box-shadow:2px 1px 2px 0 rgba(0,0,0,.15)}

.sidebar.responsive-max .sidebar-toggle.sidebar-collapse,.sidebar.responsive-max:before{display:none}

.sidebar.responsive-max .sidebar-toggle.sidebar-expand{display:block; z-index:1}

.sidebar.responsive-max+.main-content{margin-left:43px!important}

.sidebar.responsive-max+.main-content .breadcrumb{margin-left:12px}

}

@media only screen and (min-width:992px){.sidebar.navbar-collapse{max-height:none}

}

@media only screen and (max-width:991px){.sidebar.navbar-collapse{position:relative; float:none!important; margin-top:auto!important; z-index:1; width:100%!important; max-height:340px; margin-left:0; margin-right:0}

.sidebar.navbar-collapse:before{display:none}

.sidebar.navbar-collapse .nav-list > li:last-child{border-bottom-width:0}

.sidebar.navbar-collapse .nav-list > li > a{padding-top:10px!important; padding-bottom:10px!important; line-height:20px; height:41px}

.sidebar.navbar-collapse .nav-list > li:before{height:43px}

.sidebar.navbar-collapse li > .arrow{display:none!important}

.sidebar.navbar-collapse .nav-wrap+.ace-scroll,.sidebar.navbar-collapse .sidebar-toggle{display:none}

.sidebar.navbar-collapse .nav-list li > .submenu{border-left-width:0}

.sidebar.navbar-collapse .sidebar-shortcuts{padding:3px 0 6px; max-height:none; border-bottom-width:0}

.sidebar.navbar-collapse+.main-content{margin-left:0!important; margin-right:0!important}

.sidebar.navbar-collapse+.main-content .breadcrumb{margin-left:12px}

.sidebar.navbar-collapse.menu-min .nav-list > li > .submenu{position:relative; left:auto; top:auto; width:auto; z-index:auto; margin-top:auto; padding-top:0; padding-bottom:0; border-width:1px 0 0!important; -webkit-box-shadow:none!important; box-shadow:none!important}

.sidebar.navbar-collapse.menu-min .nav-list > li > .submenu:before,.sidebar.navbar-collapse.menu-min .nav-list > li > .submenu > li:before{display:block}

.sidebar.navbar-collapse.menu-min .nav-list > li > .submenu li > a > .menu-icon{left:10px}

.sidebar.navbar-collapse.menu-min .nav-list > li > .submenu:after{display:none}

.sidebar.navbar-collapse.menu-min .nav-list > li > .submenu li > a{padding:7px 0 9px 37px}

.sidebar.navbar-collapse.menu-min .nav-list > li > .submenu li > .submenu > li > a{margin-left:20px; padding-left:22px}

.sidebar.navbar-collapse.menu-min .nav-list > li > .submenu li > .submenu > li > .submenu > li > a{margin-left:20px; padding-left:38px}

.sidebar.navbar-collapse.menu-min .nav-list > li > a:hover:before{width:3px}

.sidebar.navbar-collapse.menu-min .nav-list > li > a{text-align:left; padding-left:7px}

.sidebar.navbar-collapse.menu-min .nav-list > li > a > .menu-icon{display:inline-block; width:auto; margin-right:2px}

.sidebar.navbar-collapse.menu-min .nav-list > li > a > .menu-text{position:relative; display:inline; line-height:normal; padding-left:0; height:auto; top:auto; left:auto; z-index:auto; width:auto; border-width:0!important; background-color:transparent!important; -webkit-box-shadow:none!important; box-shadow:none!important}

.sidebar.navbar-collapse.menu-min .nav-list a > .arrow{display:block}

.sidebar.navbar-collapse.menu-min .nav-list > li:hover > .submenu{display:none!important}

.sidebar.navbar-collapse.menu-min .nav-list > li.active > .submenu,.sidebar.navbar-collapse.menu-min .nav-list > li > .submenu.nav-show{display:block!important}

.sidebar.navbar-collapse.menu-min .nav-list > li > .submenu.nav-hide{display:none!important}

.sidebar.navbar-collapse.menu-min .sidebar-shortcuts-large{display:block; position:static; -webkit-box-shadow:none!important; box-shadow:none!important; border-width:0!important; background-color:transparent!important; width:auto; padding:0}

.sidebar.navbar-collapse.menu-min .sidebar-shortcuts-mini{display:none}

.sidebar.navbar-collapse .nav-list a .badge,.sidebar.navbar-collapse .nav-list a .label{position:relative; top:-1px; right:auto; left:4px}

.sidebar.navbar-collapse .nav-list li.active > a:after,.sidebar.navbar-collapse .nav-list li.active > a:before,.sidebar.navbar-collapse .nav-list > li.active:after,.sidebar.navbar-collapse .nav-list > li.highlight.active > a:after,.sidebar.navbar-collapse .nav-list > li.highlight.active > a:before{display:none!important}

}

@media (max-width:991px) and (max-height:400px){.sidebar.navbar-collapse{max-height:300px}

}

@media (max-width:991px) and (max-height:350px){.sidebar.navbar-collapse{max-height:250px}

}

@media (max-width:991px) and (max-height:300px){.sidebar.navbar-collapse{max-height:200px}

}

@media (max-width:991px) and (max-height:250px){.sidebar.navbar-collapse{max-height:150px}

}

@media (min-width:992px) and (max-width:991px){.sidebar.navbar-collapse .nav-list > li > .submenu li.hover > .submenu > li > a,.sidebar.navbar-collapse .nav-list > li > .submenu li > .submenu > li.hover > .submenu > li > a{margin-left:20px!important}

}

@media (max-width:991px){.navbar-fixed-top+.main-container .sidebar.navbar-collapse{position:fixed; z-index:1027}

}

@media (min-width:768px) and (max-width:991px){.navbar-fixed-top+.main-container.container .sidebar.navbar-collapse{width:744px!important}

}

.main-content-inner{float:left; width:100%}

.main-content .sidebar:before{top:150px}

@media only screen and (min-width:992px){body.mob-safari .sidebar.h-sidebar+.main-content .sidebar.sidebar-fixed:not(.h-sidebar){top:131px}

body.mob-safari .sidebar.h-sidebar.no-gap+.main-content .sidebar.sidebar-fixed:not(.h-sidebar){top:114px}

body.mob-safari .sidebar.h-sidebar.menu-min+.main-content .sidebar.sidebar-fixed:not(.h-sidebar){top:106px}

body.mob-safari .sidebar.h-sidebar.menu-min.no-gap+.main-content .sidebar.sidebar-fixed:not(.h-sidebar){top:89px}

.h-navbar.navbar-fixed-top+.main-container .sidebar:not(.h-sidebar):before{top:150px}

.h-navbar.navbar-fixed-top+.main-container .sidebar:not(.h-sidebar) .nav-list{padding-top:3px}

.h-navbar.navbar-fixed-top+.main-container .sidebar:not(.h-sidebar) .sidebar-shortcuts~.nav-list,.h-navbar.navbar-fixed-top+.main-container .sidebar:not(.h-sidebar) .sidebar-shortcuts~.nav-wrap-up .nav-list{padding-top:0}

.h-navbar.navbar-fixed-top+.main-container .sidebar:not(.h-sidebar) .sidebar-shortcuts{padding-top:3px; min-height:43px}

.h-navbar.navbar-fixed-top+.main-container .sidebar:not(.h-sidebar).menu-min .sidebar-shortcuts-large{line-height:40px}

.h-navbar.navbar-fixed-top+.main-container .main-content .sidebar:not(.h-sidebar) .nav-list{padding-top:0}

.h-navbar.navbar-fixed-top+.main-container .main-content .sidebar.sidebar-fixed{z-index:1026}

.main-content .h-sidebar{padding-top:1px; margin-top:0}

.main-content .h-sidebar:before{display:none}

.main-content .h-sidebar+.page-content{margin-left:auto}

.main-content .h-sidebar.sidebar-fixed{left:190px}

.main-content .h-sidebar.sidebar-fixed+.page-content{margin-top:74px}

.main-content .h-sidebar.sidebar-fixed.menu-min+.page-content{margin-top:49px}

.main-content .h-sidebar.menu-min .nav-list > li > a{padding:8px 12px 7px}

.sidebar.compact+.main-content .h-sidebar.sidebar-fixed{left:105px}

.sidebar.menu-min+.main-content .h-sidebar.sidebar-fixed{left:43px}

.navbar.navbar-fixed-top+.main-container .main-content .h-sidebar.sidebar-fixed{padding-top:3px}

}

.container.main-container .sidebar+.main-content .h-sidebar.sidebar-fixed{left:auto; right:auto}

@media (min-width:992px){.container.main-container .sidebar.compact+.main-content .h-sidebar.sidebar-fixed{width:859px}

.container.main-container .main-content .h-sidebar.sidebar-fixed{width:964px}

.container.main-container .sidebar+.main-content .h-sidebar.sidebar-fixed{width:774px}

.container.main-container .sidebar.menu-min+.main-content .h-sidebar.sidebar-fixed{width:921px}

}

@media (min-width:1200px){.container.main-container .sidebar.compact+.main-content .h-sidebar.sidebar-fixed{width:1059px}

.container.main-container .main-content .h-sidebar.sidebar-fixed{width:1164px}

.container.main-container .sidebar+.main-content .h-sidebar.sidebar-fixed{width:974px}

.container.main-container .sidebar.menu-min+.main-content .h-sidebar.sidebar-fixed{width:1121px}

}

@media only screen and (max-width:991px){.main-container .main-content .sidebar.sidebar-fixed{z-index:1026}

.navbar-fixed-top+.main-container .main-content .sidebar{position:fixed; z-index:1026}

}

.sidebar-scroll .sidebar-shortcuts{min-height:47px; border-bottom:1px solid}

.sidebar-scroll .sidebar-toggle{border-top:1px solid}

.sidebar-scroll .nav-list > li:first-child{border-top-width:0}

.sidebar-scroll .nav-list > li:last-child{border-bottom-width:0}

.sidebar-scroll .nav-wrap .sidebar-shortcuts{min-height:40px; border-bottom-width:0}

.sidebar-scroll .nav-wrap .sidebar-shortcuts+.nav-list > li:first-child,.sidebar-scroll .nav-wrap .sidebar-shortcuts+.nav-wrap-up .nav-list > li:first-child{border-top-width:1px}

.sidebar-scroll .nav-wrap-t .sidebar-toggle{border-top-width:0}

.sidebar-scroll .nav-wrap-t .nav-list > li:last-child{border-bottom-width:1px}

.sidebar .nav-wrap+.ace-scroll{position:absolute; right:0; top:0; z-index:1}

.sidebar .nav-wrap+.scrollout{right:-8px}

.sidebar .submenu.sub-scroll{overflow-y:scroll; overflow-x:hidden; -webkit-overflow-scrolling:touch}

.ace-scroll,.scroll-content{overflow:hidden}

.scroll-content{position:static}

.scroll-disabled.ace-scroll,.scroll-disabled.ace-scroll .scroll-content{overflow:visible}

.scroll-track{position:absolute; top:auto; bottom:auto; right:0; height:auto; background-color:#E7E7E7; z-index:99; width:0; opacity:0}

.scroll-bar{position:absolute; top:0; left:0; width:inherit; background:#bbd4e5}

.scroll-track.scroll-hz{top:auto; left:auto; right:auto; bottom:0; height:8px; width:auto}

.scroll-hz .scroll-bar{height:inherit; width:auto}

.scroll-track.scroll-active{-webkit-transition:width .25s ease .75s,opacity .25s ease .75s; -o-transition:width .25s ease .75s,opacity .25s ease .75s; transition:width .25s ease .75s,opacity .25s ease .75s}

.ace-scroll:hover .scroll-active,.scroll-active.scroll-track.active,.scroll-active.scroll-track.scroll-hover,.scroll-active.scroll-track:active,.scroll-active.scroll-track:hover{width:8px; opacity:1; -webkit-transition-duration:.15s; transition-duration:.15s; -webkit-transition-delay:0s; transition-delay:0s}

.scroll-track.active > .scroll-bar{transition-property:none!important; transition-duration:0s!important}

.scroll-track.scroll-margin{margin-left:-1px}

.scroll-track.scroll-left{right:auto; left:0}

.scroll-track.scroll-left.scroll-margin{margin-left:1px}

.scroll-track.scroll-top{bottom:auto; top:0}

.scroll-track.scroll-top.scroll-margin{top:1px}

.scroll-dark .scroll-bar{background-color:transparent; background-color:rgba(0,0,0,.25); filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#40000000', endColorstr='#40000000', GradientType=0 )}

.scroll-track.scroll-dark{background-color:transparent; background-color:rgba(0,0,0,.15); filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#26000000', endColorstr='#26000000', GradientType=0 )}

.scroll-light .scroll-bar{background-color:transparent; background-color:rgba(0,0,0,.14); filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#24000000', endColorstr='#24000000', GradientType=0 )}

.scroll-track.scroll-light{background-color:transparent; background-color:rgba(0,0,0,.07); filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#12000000', endColorstr='#12000000', GradientType=0 )}

.scroll-white .scroll-bar{background-color:transparent; background-color:rgba(255,255,255,.33); filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#55FFFFFF', endColorstr='#55FFFFFF', GradientType=0 )}

.scroll-track.scroll-white{background-color:transparent; background-color:rgba(255,255,255,.2); filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#33FFFFFF', endColorstr='#33FFFFFF', GradientType=0 )}

.no-track.scroll-track{background-color:transparent; filter:progid:DXImageTransform.Microsoft.gradient( enabled=false )}

.scroll-track.scroll-visible{opacity:1; width:8px}

.scroll-hz.scroll-visible{height:8px; width:auto}

.ace-scroll:hover .scroll-thin.scroll-track,.scroll-thin.scroll-track.active,.scroll-thin.scroll-track.scroll-hover,.scroll-thin.scroll-track.scroll-visible,.scroll-thin.scroll-track:active,.scroll-thin.scroll-track:hover{width:6px}

.ace-scroll:hover .scroll-thin.scroll-hz,.scroll-thin.scroll-hz.active,.scroll-thin.scroll-hz.scroll-hover,.scroll-thin.scroll-hz.scroll-visible,.scroll-thin.scroll-hz:active,.scroll-thin.scroll-hz:hover{width:auto; height:6px}

.scroll-chrome.scroll-active .scroll-bar{background-color:transparent; width:11px}

.scroll-chrome.scroll-active .scroll-bar:before{display:block; content:""; position:absolute; top:1px; bottom:3px; left:1px; right:1px; background-color:#D9D9D9; border:1px solid #BBB; border-radius:1px}

.scroll-chrome.scroll-active .scroll-track{width:12px; background-color:#F2F2F2; border:1px solid; border-width:1px 0 1px 1px; border-color:#E6E6E6 transparent #E6E6E6 #DBDBDB}

.scroll-chrome.scroll-active .scroll-track:hover .scroll-bar:before{background-color:silver; border-color:#A6A6A6}

.scroll-chrome.scroll-active .scroll-track.active .scroll-bar:before{background-color:#A9A9A9; border-color:#8B8B8B}

.scroll-active.scroll-track.idle-hide{opacity:0; filter:alpha(opacity=0); width:8px}

.scroll-active.scroll-track.idle-hide.not-idle{width:8px; opacity:1; filter:alpha(opacity=100)}

.scroll-active.scroll-thin.scroll-track.idle-hide{width:6px}

.scroll-active.scroll-chrome .scroll-track.idle-hide{width:12px}

.nav-wrap+.scroll-active .scroll-track{width:8px}

.nav-scroll.scroll-active .scroll-track{width:8px; right:0}

.nav-scroll.scroll-active:hover .scroll-track,.nav-wrap:hover+.scroll-active .scroll-track{width:8px; opacity:1; -webkit-transition-duration:.15s; transition-duration:.15s; -webkit-transition-delay:0s; transition-delay:0s}

.nav-scroll.scroll-active:hover .scroll-track{width:8px}

.nav-scroll.ace-scroll,.nav-scroll.ace-scroll .scroll-content{overflow:hidden}

.modal.aside-vc,.nav-scroll.ace-scroll.scroll-disabled,.nav-scroll.ace-scroll.scroll-disabled .scroll-content{overflow:visible}

.scroll-track.scroll-detached{position:absolute; z-index:1029; bottom:auto; right:auto}

.modal-content{border-radius:0; -webkit-box-shadow:none; box-shadow:none}

.modal-footer{padding-top:12px; padding-bottom:14px; border-top-color:#E4E9EE; -webkit-box-shadow:none; box-shadow:none; background-color:#EFF3F8}

.modal-header .close{font-size:32px}

.modal-body.padding-25{padding-left:25px; padding-right:25px}

.modal.aside-dark .modal-content{background-color:rgba(0,0,0,.85); filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#D8000000', endColorstr='#D8000000', GradientType=0 )}

.modal.aside{z-index:999; position:absolute}

.aside.aside-contained,.modal.aside-fixed.aside-vc,.modal.aside.in{position:fixed}

.navbar-fixed-top~.modal.aside-vc{z-index:1025}

.modal.aside-fixed.aside-hz,.navbar-fixed-bottom~.modal.aside-hz,.navbar-fixed-top~.modal.aside-hz{position:fixed; z-index:1049}

.modal.aside.in{z-index:1050}

.modal.aside-vc{margin:auto; width:0; left:auto; right:auto; top:0; bottom:0; display:block!important}

.modal.in.aside-vc{width:100%}

.modal.aside-vc .modal-dialog{margin:inherit; overflow:inherit; width:250px; max-width:66%; height:inherit; position:inherit; right:inherit; top:inherit; bottom:inherit; left:inherit; opacity:1; transition:transform .3s ease-out 0s; -webkit-transition:-webkit-transform .3s ease-out 0s}

@media only screen and (max-width:319px){.modal.aside-vc .modal-dialog{max-width:none; width:200px}

.aside-contained.aside-vc .modal-dialog{width:180px}

}

@media only screen and (max-width:240px){.modal.aside-vc .modal-dialog{max-width:none; width:160px}

.aside-contained.aside-vc .modal-dialog{width:140px}

}

.modal.aside-vc .modal-content{height:100%; overflow:hidden}

.modal.in.aside-vc .modal-dialog{transform:none; -webkit-transform:none; height:auto}

.modal.aside-vc .aside-trigger{position:absolute; top:155px; right:auto; left:auto; bottom:auto; margin-top:-1px; width:37px; outline:0}

.modal.aside-vc .aside-trigger.ace-settings-btn{width:42px}

.modal.in.aside-vc .aside-trigger{z-index:-1}

.aside.modal.in .modal-backdrop.in+.modal-dialog .aside-trigger{z-index:auto}

@media only screen and (max-height:240px){.modal.aside-vc .aside-trigger{top:130px}

}

.modal.aside-vc.navbar-offset .modal-dialog{top:45px}

.modal.aside-vc.navbar-offset .modal-dialog .aside-trigger{top:110px}

@media (max-width:479px){.navbar:not(.navbar-collapse)~.modal.aside-vc.navbar-offset .modal-dialog{top:90px}

}

.modal.aside-right{right:0}

.modal.aside-right .modal-content{border-width:0 0 0 1px; box-shadow:-2px 1px 2px 0 rgba(0,0,0,.15)}

.modal.aside-right .aside-trigger{right:100%}

.modal.aside-right .modal-dialog{transform:translateX(100%); -webkit-transform:translateX(100%)}

.modal.aside-left{left:0}

.modal.aside-left .modal-content{border-width:0 1px 0 0; box-shadow:2px -1px 2px 0 rgba(0,0,0,.15)}

.modal.aside-left .aside-trigger{left:100%}

.modal.aside-right .aside-trigger.btn.ace-settings-btn{border-radius:6px 0 0 6px}

.modal.aside-left .aside-trigger.btn.ace-settings-btn{border-radius:0 6px 6px 0}

.modal.aside-left .modal-dialog{transform:translateX(-100%); -webkit-transform:translateX(-100%)}

.modal.aside-hz{margin:auto; height:0; left:0; right:0; top:auto; bottom:auto; display:block!important; overflow:visible}

.aside.aside-hidden .modal-content,.wz-finish.ng-hide,.wz-next.ng-hide{display:none}

.modal.in.aside-hz{height:100%}

.modal.aside-hz .modal-dialog{margin:inherit; height:auto; overflow:inherit; max-height:50%; width:inherit; position:inherit; right:inherit; top:inherit; bottom:inherit; left:inherit; opacity:1; transition:transform .3s ease-out 0s; -webkit-transition:-webkit-transform .3s ease-out 0s}

@media only screen and (max-height:320px){.modal.aside-hz .modal-dialog{max-height:66%}

}

.modal.aside-hz .modal-content{width:100%; overflow:hidden}

.modal.in.aside-hz .modal-dialog{transform:none; -webkit-transform:none; height:auto}

.modal.aside-hz .aside-trigger{position:absolute; top:auto; right:auto; bottom:auto; margin-top:-1px; z-index:auto; outline:0; margin-left:-15px; left:50%}

.modal.aside-hz .aside-trigger.ace-settings-btn{margin-left:-20px}

.modal.in.aside-hz .aside-trigger{z-index:-1}

.modal.aside-top{top:0}

.modal.aside-top .modal-dialog{transform:translateY(-100%); -webkit-transform:translateY(-100%)}

.modal.aside-top .modal-content{border-width:0; box-shadow:1px 2px 2px 0 rgba(0,0,0,.15)}

.modal.aside-bottom{bottom:0}

.modal.aside-bottom .modal-dialog{transform:translateY(100%); -webkit-transform:translateY(100%)}

.modal.aside-bottom .modal-content{border-width:0; box-shadow:-1px 2px 2px 0 rgba(0,0,0,.15)}

.modal.aside-bottom .aside-trigger{bottom:100%; margin-top:auto; margin-bottom:-1px}

.modal.aside-top .aside-trigger.ace-settings-btn{border-radius:0 0 6px 6px!important}

.modal.aside-bottom .aside-trigger.ace-settings-btn{border-radius:6px 6px 0 0!important}

@media only screen and (min-width:768px){.container.main-container~.modal.aside-vc .modal-dialog{transition:none; -webkit-transition:none}

.container.main-container~.modal.aside-vc:not(.in) .modal-content{display:none}

}

.aside-vc.in.no-backdrop{width:auto}

.aside-hz.in.no-backdrop{height:auto}

.modal.aside .modal-backdrop{position:fixed; bottom:0; height:auto!important}

.modal.aside-hz .aside-trigger.align-left{left:15px; margin-left:auto; text-align:center}

.modal.aside-hz .aside-trigger.align-right{text-align:center; left:auto; margin-left:auto; right:15px}

.modal.transition-off .modal-dialog{transition:none; -webkit-transition:none}

.modal.ng-aside .modal-content{box-shadow:0 5px 15px rgba(0,0,0,.5)}

.modal.ng-aside.bottom .modal-content{border-top:1px solid rgba(0,0,0,.2)}

.modal.ng-aside.top .modal-content{border-bottom:1px solid rgba(0,0,0,.2)}

.modal.ng-aside.left .modal-content{border-right:1px solid rgba(0,0,0,.2)}

.modal.ng-aside.right .modal-content{border-left:1px solid rgba(0,0,0,.2)}

.modal-backdrop.in[ng-class]{bottom:0}

.ng-aside .modal-dialog{width:320px}

.breadcrumb,.breadcrumb > li > a,.btn{display:inline-block}

.breadcrumbs{position:relative; z-index:auto; border-bottom:1px solid #E5E5E5; background-color:#F5F5F5; min-height:47px; line-height:40px; padding:0 12px 0 0}

.breadcrumb{background-color:transparent; line-height:20px; margin:6px 22px 0 12px; padding:0; font-size:13px; color:#333; border-radius:0}

.breadcrumb > li,.breadcrumb > li.active{color:#555; padding:0 3px}

.breadcrumb > li > a{color:#4C8FBD}

.breadcrumb > li+li:before{font-family:FontAwesome; font-size:14px; content:"\f105"; color:#B2B6BF; padding:0; margin:0 8px 0 0; position:relative; top:1px}

.breadcrumb .home-icon{font-size:20px; margin-left:2px; margin-right:2px; vertical-align:top}

@media only screen and (max-width:480px){.modal.aside-vc .modal-dialog{width:240px}

.breadcrumb > li > a{padding:0 1px}

}

@media only screen and (max-width:991px){.menu-toggler+.sidebar.responsive+.main-content .breadcrumb{margin-left:90px}

}

@media only screen and (max-width:320px){.modal.aside-vc .modal-dialog{width:200px}

.breadcrumb{margin-left:8px}

.menu-toggler+.sidebar.responsive+.main-content .breadcrumb{margin-left:36px}

}

@media (min-width:992px){.breadcrumbs-fixed{position:fixed; right:0; left:0; top:auto; z-index:1024}

.breadcrumbs-fixed+.page-content{padding-top:49px}

.sidebar+.main-content .breadcrumbs-fixed{left:190px}

body.mob-safari .breadcrumbs-fixed{top:45px}

.sidebar.compact+.main-content .breadcrumbs-fixed{left:105px}

.sidebar.menu-min+.main-content .breadcrumbs-fixed{left:43px}

}

@media (min-width:992px) and (max-width:991px){.breadcrumbs-fixed,.sidebar.menu-min+.main-content .breadcrumbs-fixed{left:0}

.sidebar.responsive-max+.main-content .breadcrumbs-fixed,.sidebar.responsive-min+.main-content .breadcrumbs-fixed{left:43px}

}

@media (min-width:992px){.container.main-container .sidebar.compact+.main-content .breadcrumbs-fixed{width:859px}

.container.main-container .breadcrumbs-fixed{left:auto; right:auto; width:964px}

.container.main-container .sidebar+.main-content .breadcrumbs-fixed{left:auto; right:auto; width:774px}

.container.main-container .sidebar.menu-min+.main-content .breadcrumbs-fixed{left:auto; right:auto; width:921px}

}

@media (min-width:1200px){.container.main-container .sidebar.compact+.main-content .breadcrumbs-fixed{width:1059px}

.container.main-container .breadcrumbs-fixed{width:1164px}

.container.main-container .sidebar+.main-content .breadcrumbs-fixed{width:974px}

.container.main-container .sidebar.menu-min+.main-content .breadcrumbs-fixed{width:1121px}

}

@media (min-width:992px) and (max-width:991px){.container.main-container .breadcrumbs-fixed,.container.main-container .sidebar.compact+.main-content .breadcrumbs-fixed,.container.main-container .sidebar.menu-min+.main-content .breadcrumbs-fixed{width:744px}

.container.main-container .sidebar.responsive-max+.main-content .breadcrumbs-fixed,.container.main-container .sidebar.responsive-min+.main-content .breadcrumbs-fixed{left:auto; right:auto; width:701px}

}

@media (min-width:992px){.h-sidebar+.main-content .breadcrumbs.breadcrumbs-fixed{position:relative; top:auto; left:auto; z-index:auto; width:auto}

.h-sidebar+.main-content .page-content{padding-top:12px}

}
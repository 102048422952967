/* =============================================================================
   HTML5 element display
   ========================================================================== */

/*
 * Corrects block display not defined in IE6/7/8/9 & FF3  
  * Copyright 2011-2017-18 Karan Soni, Inc. 
*/


/*@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');*/
/*@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i');*/
/*@import url("css/font-awesome.css");*/
/*@import url("css/animate.css");*/
/*@import url("css/ace.min.css");*/


body { margin:0; padding:0; font-family: 'Roboto', sans-serif; font-size:1.4em; line-height:21px; background:#e2e3e4; overflow-x:hidden; }


#main { width:100%; float:left; }


/*--------------------------- Header Section --------------------------- */

#header { width:100%; float:left; margin-bottom:45px; }

#logo { width:100%; float:left; text-align:center; margin:15px 0 0 0; padding:0; }


/* Right Side */

#right-header { width:100%; float:left; margin:15px 0px 0px 0px; }


/* General sizing */
ul.dropdown-lr {  width: 300px; }

/* mobile fix */
@media (max-width: 768px) {
	
.dropdown-lr h3 { color: #000; }

.dropdown-lr label {	color: #000; }
}


.already-member { width:100%; float:left; padding:10px 15px 10px 0; text-align:center; }
.already-member a { color:#fff; text-decoration:none; text-shadow:0px 1px 0px #075063; } 
.already-member a:hover { color:#fff; text-decoration:underline; } 


.nav1 {  padding-left: 0;  margin-bottom: 0;  list-style: none; }

.nav1 > li { position: relative;  display: block;  }

.nav1 > li > a { color:#34474f; position: relative; font-weight:700;  display: block;  padding: 0px 20px;  }

.nav1 > li > a > i { margin-right:5px; }

.nav1 > li > a:hover,.nav1 > li > a:focus { text-decoration: none; }


/* Sign Up Page */

.nav1 > li > a.login { color:#fff; position: relative; font-family: 'Lato', sans-serif; font-weight:700;  display: block; text-align:center;  padding: 10px 20px; background:#f7943d; border-radius:4px; border-bottom:2px solid #ce6f1b; text-shadow:0px 2px 0px #ce6f1b;   }

.nav1 > li > a.login > i { margin-right:5px; }

.nav1 > li > a.login:hover,.nav1 > li > a.login:focus { text-decoration: none;  background:#33a5bd; border-bottom:2px solid #05667e; text-shadow:0px 2px 0px #2b97ae; -webkit-transition:background 0.6s; -moz-transition:background 0.6s;  -o-transition:background 0.6s;  transition:background 0.6s; }



.nav1 > li.disabled > a { color: #fff; }

.nav1 > li.disabled > a:hover,.nav1 > li.disabled > a:focus {  color: #fff;  text-decoration: none; cursor: not-allowed;  background-color: transparent; }

.nav1 .open > a,.nav1 .open > a:hover,.nav1 .open > a:focus { }

.nav1 .nav-divider {  height: 1px;  margin: 9px 0;  overflow: hidden;  background-color: #e5e5e5; }

.nav1 > li > a > img {  max-width: none; }

.dropdown-title  { width:100%; float:left; font-size:1em; color:#000; font-weight:700; text-align:center; text-transform:uppercase; margin:10px 0 10px 0; padding:0; }

.social-buttons { margin:12px 0; }

.social-buttons a { width: 49%; }

.form-group { margin-bottom: 10px; }

.btn-fb{ color: #fff;  background:#3b5998; border-bottom:2px solid #2c4883; }
.btn-fb:hover{ color: #fff; background:#496ebc; border-bottom:2px solid #2c4883; }

.btn-tw { color: #fff; background:#55acee; border-bottom:2px solid #2480c5; }
.btn-tw:hover { color: #fff; background:#59b5fa; border-bottom:2px solid #2480c5; }



/*-------------------- Parallax Style Section --------------------*/

.parallex-contain {	padding-top:40px; padding-bottom:40px;	width:100%;	position:relative; overflow:hidden; }

.parallex-contain1 { padding-top:40px;	width:100%;	position:relative; overflow:hidden; }

.parallex-contain2 { padding:15px 0;	width:100%;	position:relative; overflow:hidden; }


/* background setup */
.background { background-repeat:no-repeat;  /* custom background-position */   background-position:50% 50%;   /* ie8- graceful degradation */    background-position:50% 50%\9 !important;
}

/* fullscreen setup */
html, body {
    /* give this to all tags from html to .fullscreen */
    height:100%;
}
.fullscreen,
.content-a {
    width:100%;
    height:100%;
    overflow:hidden;
}
.fullscreen.overflow,
.fullscreen.overflow .content-a {
    height:auto;
    min-height:100%;
}

/* content centering styles */
.content-a {
    display:table;
}
.content-b {
	display:table-cell;
    position:relative;
	vertical-align:middle;
	text-align:center;
}


.not-fullscreen {  height:50%; }


/*--------------------------- Typography Section --------------------------- */

img { max-width:100%; height:auto; border:none; } 

iframe { width:100%; float:left;  }

h1,h2,h3,h4,h5,h6 { font-family: 'Open Sans', sans-serif; }

.screen-reader-response  { display:none; }



/* Form Style */

input[type="text"],input[type="email"],input[type="tel"],input[type="password"],input[type="number"]  { width: 100%; height: 40px; padding: 6px 9px; margin-bottom:5px; font-size: 14px; line-height: 1.42857143; color:#585858; background:#fff; border:1px solid #e5e5e5; border-radius: 4px; -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }

input[type="text"]:focus,input[type="email"]:focus,input[type="tel"]:focus,input[type="password"]:focus,input[type="number"]:focus { border-color: #66afe9; outline: 0; -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6); box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6); }


textarea  { width: 100%; height: 80px; padding: 6px 9px; margin-bottom:5px; font-size: 14px; line-height: 1.42857143; color:#585858; background:#fff; border:1px solid #e5e5e5; border-radius: 4px; -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }

textarea:focus { border-color: #66afe9; outline: 0; -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6); box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6); }


input[type="submit"],input[type="button"] { font-family: 'Roboto', sans-serif; font-size:1em; font-weight:700; float:left; color:#fff; text-decoration:none; text-transform:uppercase; border-radius:4px; padding:8px 15px; margin-right:10px; background:#017ea4; border:1px solid #017ea4; border-bottom:2px solid #005c77; }

input[type="submit"]:focus,input[type="button"]:focus { color:#fff; text-decoration:none; border-radius:4px; background:#f7943d; border:1px solid #f7943d; border-bottom:2px solid #ce6f1b; -webkit-transition:background 0.6s; -moz-transition:background 0.6s;  -o-transition:background 0.6s;  transition:background 0.6s; }

input[type="submit"]:hover,input[type="button"]:hover { color:#fff; text-decoration:none; border-radius:4px; background:#f7943d; border:1px solid #f7943d; border-bottom:2px solid #ce6f1b; -webkit-transition:background 0.6s; -moz-transition:background 0.6s;  -o-transition:background 0.6s;  transition:background 0.6s; }


input[type="reset"] { font-family: 'Roboto', sans-serif; font-size:1em; font-weight:700; float:left; color:#fff; text-decoration:none; text-transform:uppercase; border-radius:4px; padding:8px 15px; margin-right:10px; background:#f7943d; border:1px solid #f7943d; border-bottom:2px solid #ce6f1b; }

input[type="reset"]:focus { color:#fff; text-decoration:none; border-radius:4px; background:#017ea4; border:1px solid #017ea4; border-bottom:2px solid #005c77; -webkit-transition:background 0.6s; -moz-transition:background 0.6s;  -o-transition:background 0.6s;  transition:background 0.6s; }

input[type="reset"]:hover { color:#fff; text-decoration:none; border-radius:4px; background:#017ea4; border:1px solid #017ea4; border-bottom:2px solid #005c77; -webkit-transition:background 0.6s; -moz-transition:background 0.6s;  -o-transition:background 0.6s;  transition:background 0.6s; }



/* Checkbox and  Radio */

input[type=checkbox]:not(old),
input[type=radio   ]:not(old){
  width     : 2em;
  margin    : 0;
  padding   : 0;
  font-size : 1em;
  opacity   : 0;
}

input[type=checkbox]:not(old) + label,
input[type=radio   ]:not(old) + label{
  display      : inline-block;
  margin-left  : -2em;
  line-height  : 1.8em;
}

input[type=checkbox]:not(old) + label > span,
input[type=radio   ]:not(old) + label > span{
  display          : inline-block;
  width            : 20px;
  height           : 20px;
  margin           : 0px 5px 0px 10px;
  border           : 1px solid #e1e1e1;
  border-radius    : 2px;
  vertical-align   : bottom;
}

input[type=checkbox]:not(old):checked + label > span,
input[type=radio   ]:not(old):checked + label > span{

}

input[type=checkbox]:not(old):checked + label > span:before{
  content     : '✓';
  display     : block;
  width       : 1em;
  color       : #007ea4;
  font-size   : 0.875em;
  line-height : 1em;
  text-align  : center;
  font-weight : bold;
}

input[type=radio]:not(old):checked +  label > span > span{
  display          : block;
  width            : 16px;
  height           : 16px;
  margin           : 1px 0 0 1px;
  border           : 0;
  border-radius    : 2px;
  background       : #007ea4;
}



/* Select Box */

.btn-default2 {  color: #4e4e4e; font-weight:400; background: #fff;  border:1px solid #e5e5e5;  }
.btn-default2:focus,.btn-default.focus {  color: #4e4e4e; background: #fff;  border:1px solid #e5e5e5;  }
.btn-default2:hover {  color: #4e4e4e; background: #fff;  border:1px solid #e5e5e5;  }

select.bs-select-hidden,select.selectpicker {  display: none !important; }

.bootstrap-select {
  width: 220px \0;
  /*IE9 and below*/
}
.bootstrap-select > .dropdown-toggle {
  width:auto;
  padding-right: 25px;
  z-index: 1;
}
.bootstrap-select > .dropdown-toggle.bs-placeholder,.bootstrap-select > .dropdown-toggle.bs-placeholder:hover,.bootstrap-select >.dropdown-toggle.bs-placeholder:focus,.bootstrap-select > .dropdown-toggle.bs-placeholder:active { color: #999; }

.bootstrap-select > select {  position: absolute !important;  bottom: 0;  left: 50%;  display: block !important;  width: 0.5px !important; height: 100% !important;  padding: 0 !important;  opacity: 0 !important;  border: none; }

.bootstrap-select > select.mobile-device {  top: 0;  left: 0;  display: block !important;  width: 100% !important;  z-index: 2; }

.has-error .bootstrap-select .dropdown-toggle,.error .bootstrap-select .dropdown-toggle { border-color: #b94a48; }

.bootstrap-select.fit-width { width: auto !important; }

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) { width: 220px; }

.bootstrap-select .dropdown-toggle:focus {  outline: thin dotted #333333 !important;  outline: 5px auto -webkit-focus-ring-color !important;  outline-offset: -2px; }

.bootstrap-select.form-control {  margin-bottom: 0;  padding: 0;  border: none; }

.bootstrap-select.form-control:not([class*="col-"]) {  width: 100%; }

.bootstrap-select.form-control.input-group-btn { z-index: auto; }

.bootstrap-select.form-control.input-group-btn:not(:first-child):not(:last-child) > .btn {  border-radius: 0; }

.bootstrap-select.btn-group:not(.input-group-btn),.bootstrap-select.btn-group[class*="col-"] { float: none;  display: inline-block;  margin-left: 0; }


.bootstrap-select.btn-group.dropdown-menu-right,
.bootstrap-select.btn-group[class*="col-"].dropdown-menu-right,
.row .bootstrap-select.btn-group[class*="col-"].dropdown-menu-right {
  float: right;
}
.form-inline .bootstrap-select.btn-group,
.form-horizontal .bootstrap-select.btn-group,
.form-group .bootstrap-select.btn-group {
  margin-bottom: 0;
}
.form-group-lg .bootstrap-select.btn-group.form-control,
.form-group-sm .bootstrap-select.btn-group.form-control {
  padding: 0;
}
.form-group-lg .bootstrap-select.btn-group.form-control .dropdown-toggle,
.form-group-sm .bootstrap-select.btn-group.form-control .dropdown-toggle {
  height: 100%;
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit;
}
.form-inline .bootstrap-select.btn-group .form-control {
  width: 100%;
}
.bootstrap-select.btn-group.disabled,
.bootstrap-select.btn-group > .disabled {
  cursor: not-allowed;
}
.bootstrap-select.btn-group.disabled:focus,
.bootstrap-select.btn-group > .disabled:focus {
  outline: none !important;
}
.bootstrap-select.btn-group.bs-container {
  position: absolute;
  height: 0 !important;
  padding: 0 !important;
}
.bootstrap-select.btn-group.bs-container .dropdown-menu {
  z-index: 1060;
}
.bootstrap-select.btn-group .dropdown-toggle .filter-option {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  text-align: left;
}
.bootstrap-select.btn-group .dropdown-toggle .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle;
  font-size:12px;
}
.bootstrap-select.btn-group[class*="col-"] .dropdown-toggle {
  width: 100%;
}
.bootstrap-select.btn-group .dropdown-menu {
  min-width: 100%;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.bootstrap-select.btn-group .dropdown-menu.inner {
  position: static;
  float: none;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.bootstrap-select.btn-group .dropdown-menu li {
  position: relative;
}
.bootstrap-select.btn-group .dropdown-menu li.active small {
  color: #fff;
}
.bootstrap-select.btn-group .dropdown-menu li.disabled a {
  cursor: not-allowed;
}
.bootstrap-select.btn-group .dropdown-menu li a {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.bootstrap-select.btn-group .dropdown-menu li a.opt {
  position: relative;
  padding-left: 2.25em;
}
.bootstrap-select.btn-group .dropdown-menu li a span.check-mark {
  display: none;
}
.bootstrap-select.btn-group .dropdown-menu li a span.text {
  display: inline-block;
}
.bootstrap-select.btn-group .dropdown-menu li small {
  padding-left: 0.5em;
}
.bootstrap-select.btn-group .dropdown-menu .notify {
  position: absolute;
  bottom: 5px;
  width: 96%;
  margin: 0 2%;
  min-height: 26px;
  padding: 3px 5px;
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  pointer-events: none;
  opacity: 0.9;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.bootstrap-select.btn-group .no-results {
  padding: 3px;
  background: #f5f5f5;
  margin: 0 5px;
  white-space: nowrap;
}
.bootstrap-select.btn-group.fit-width .dropdown-toggle .filter-option {
  position: static;
}
.bootstrap-select.btn-group.fit-width .dropdown-toggle .caret {
  position: static;
  top: auto;
  margin-top: -1px;
}
.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  margin-top: 5px;
}
.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
  margin-right: 34px;
}
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle {
  z-index: 1061;
}
.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
  content: '';
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(204, 204, 204, 0.2);
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none;
}
.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none;
}
.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:before {
  bottom: auto;
  top: -3px;
  border-top: 7px solid rgba(204, 204, 204, 0.2);
  border-bottom: 0;
}
.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:after {
  bottom: auto;
  top: -3px;
  border-top: 6px solid white;
  border-bottom: 0;
}
.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:before {
  right: 12px;
  left: auto;
}
.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:after {
  right: 13px;
  left: auto;
}
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:before,
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:after {
  display: block;
}
.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
  padding: 4px 8px;
}
.bs-actionsbox {
  width: 100%;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.bs-actionsbox .btn-group button {
  width: 50%;
}
.bs-donebutton {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.bs-donebutton .btn-group button {
  width: 100%;
}
.bs-searchbox + .bs-actionsbox {
  padding: 0 8px 4px;
}
.bs-searchbox .form-control {
  margin-bottom: 0;
  width: 100%;
  float: none;
}



/* Date Time picker */

.datetimepicker { width:220px; padding: 4px;
	margin-top: 1px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	direction: ltr;
}

.datetimepicker-inline {
	width: 220px;
}

.datetimepicker.datetimepicker-rtl {
	direction: rtl;
}

.datetimepicker.datetimepicker-rtl table tr td span {
	float: right;
}

.datetimepicker-dropdown, .datetimepicker-dropdown-left {
	top: 0;
	left: 0;
}

[class*=" datetimepicker-dropdown"]:before {
	content: '';
	display: inline-block;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #cccccc;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	position: absolute;
}

[class*=" datetimepicker-dropdown"]:after {
	content: '';
	display: inline-block;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #ffffff;
	position: absolute;
}

[class*=" datetimepicker-dropdown-top"]:before {
	content: '';
	display: inline-block;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-top: 7px solid #cccccc;
	border-top-color: rgba(0, 0, 0, 0.2);
	border-bottom: 0;
}

[class*=" datetimepicker-dropdown-top"]:after {
	content: '';
	display: inline-block;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 6px solid #ffffff;
	border-bottom: 0;
}

.datetimepicker-dropdown-bottom-left:before {
	top: -7px;
	right: 6px;
}

.datetimepicker-dropdown-bottom-left:after {
	top: -6px;
	right: 7px;
}

.datetimepicker-dropdown-bottom-right:before {
	top: -7px;
	left: 6px;
}

.datetimepicker-dropdown-bottom-right:after {
	top: -6px;
	left: 7px;
}

.datetimepicker-dropdown-top-left:before {
	bottom: -7px;
	right: 6px;
}

.datetimepicker-dropdown-top-left:after {
	bottom: -6px;
	right: 7px;
}

.datetimepicker-dropdown-top-right:before {
	bottom: -7px;
	left: 6px;
}

.datetimepicker-dropdown-top-right:after {
	bottom: -6px;
	left: 7px;
}

.datetimepicker > div {
	display: none;
}

.datetimepicker.minutes div.datetimepicker-minutes {
	display: block;
}

.datetimepicker.hours div.datetimepicker-hours {
	display: block;
}

.datetimepicker.days div.datetimepicker-days {
	display: block;
}

.datetimepicker.months div.datetimepicker-months {
	display: block;
}

.datetimepicker.years div.datetimepicker-years {
	display: block;
}

.datetimepicker table {
	margin: 0;
}

.datetimepicker  td,
.datetimepicker th {
	text-align: center;
	width: 20px;
	height: 20px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	border: none;
}

.table-striped .datetimepicker table tr td,
.table-striped .datetimepicker table tr th {
	background-color: transparent;
}

.datetimepicker table tr td.minute:hover {
	background: #eeeeee;
	cursor: pointer;
}

.datetimepicker table tr td.hour:hover {
	background: #eeeeee;
	cursor: pointer;
}

.datetimepicker table tr td.day:hover {
	background: #eeeeee;
	cursor: pointer;
}

.datetimepicker table tr td.old,
.datetimepicker table tr td.new {
	color: #999999;
}

.datetimepicker table tr td.disabled,
.datetimepicker table tr td.disabled:hover {
	background: none;
	color: #999999;
	cursor: default;
}

.datetimepicker table tr td.today,
.datetimepicker table tr td.today:hover,
.datetimepicker table tr td.today.disabled,
.datetimepicker table tr td.today.disabled:hover { color:#fff; background:#f7943d; }

.datetimepicker table tr td.today:hover,
.datetimepicker table tr td.today:hover:hover,
.datetimepicker table tr td.today.disabled:hover,
.datetimepicker table tr td.today.disabled:hover:hover,
.datetimepicker table tr td.today:active,
.datetimepicker table tr td.today:hover:active,
.datetimepicker table tr td.today.disabled:active,
.datetimepicker table tr td.today.disabled:hover:active,
.datetimepicker table tr td.today.active,
.datetimepicker table tr td.today:hover.active,
.datetimepicker table tr td.today.disabled.active,
.datetimepicker table tr td.today.disabled:hover.active,
.datetimepicker table tr td.today.disabled,
.datetimepicker table tr td.today:hover.disabled,
.datetimepicker table tr td.today.disabled.disabled,
.datetimepicker table tr td.today.disabled:hover.disabled,
.datetimepicker table tr td.today[disabled],
.datetimepicker table tr td.today:hover[disabled],
.datetimepicker table tr td.today.disabled[disabled],
.datetimepicker table tr td.today.disabled:hover[disabled] {
	background-color: #f7943d;
}

.datetimepicker table tr td.today:active,
.datetimepicker table tr td.today:hover:active,
.datetimepicker table tr td.today.disabled:active,
.datetimepicker table tr td.today.disabled:hover:active,
.datetimepicker table tr td.today.active,
.datetimepicker table tr td.today:hover.active,
.datetimepicker table tr td.today.disabled.active,
.datetimepicker table tr td.today.disabled:hover.active {
	background-color: #f7943d;
}

.datetimepicker table tr td.active,
.datetimepicker table tr td.active:hover,
.datetimepicker table tr td.active.disabled,
.datetimepicker table tr td.active.disabled:hover { color:#fff; background:#007ea4; }

.datetimepicker table tr td.active:hover,
.datetimepicker table tr td.active:hover:hover,
.datetimepicker table tr td.active.disabled:hover,
.datetimepicker table tr td.active.disabled:hover:hover,
.datetimepicker table tr td.active:active,
.datetimepicker table tr td.active:hover:active,
.datetimepicker table tr td.active.disabled:active,
.datetimepicker table tr td.active.disabled:hover:active,
.datetimepicker table tr td.active.active,
.datetimepicker table tr td.active:hover.active,
.datetimepicker table tr td.active.disabled.active,
.datetimepicker table tr td.active.disabled:hover.active,
.datetimepicker table tr td.active.disabled,
.datetimepicker table tr td.active:hover.disabled,
.datetimepicker table tr td.active.disabled.disabled,
.datetimepicker table tr td.active.disabled:hover.disabled,
.datetimepicker table tr td.active[disabled],
.datetimepicker table tr td.active:hover[disabled],
.datetimepicker table tr td.active.disabled[disabled],
.datetimepicker table tr td.active.disabled:hover[disabled] { color:#fff; background:#007ea4; }

.datetimepicker table tr td.active:active,
.datetimepicker table tr td.active:hover:active,
.datetimepicker table tr td.active.disabled:active,
.datetimepicker table tr td.active.disabled:hover:active,
.datetimepicker table tr td.active.active,
.datetimepicker table tr td.active:hover.active,
.datetimepicker table tr td.active.disabled.active,
.datetimepicker table tr td.active.disabled:hover.active {	color:#fff; background:#007ea4; }

.datetimepicker table tr td span {
	display: block;
	width: 23%;
	height: 54px;
	line-height: 54px;
	float: left;
	margin: 1%;
	cursor: pointer;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}

.datetimepicker .datetimepicker-hours span {
	height: 26px;
	line-height: 26px;
}

.datetimepicker .datetimepicker-hours table tr td span.hour_am,
.datetimepicker .datetimepicker-hours table tr td span.hour_pm {
	width: 14.6%;
}

.datetimepicker .datetimepicker-hours fieldset legend,
.datetimepicker .datetimepicker-minutes fieldset legend {
	margin-bottom: inherit;
	line-height: 30px;
}

.datetimepicker .datetimepicker-minutes span {
	height: 26px;
	line-height: 26px;
}

.datetimepicker table tr td span:hover {
	background: #eeeeee;
}

.datetimepicker table tr td span.disabled,
.datetimepicker table tr td span.disabled:hover {
	background: none;
	color: #999999;
	cursor: default;
}

.datetimepicker table tr td span.active,
.datetimepicker table tr td span.active:hover,
.datetimepicker table tr td span.active.disabled,
.datetimepicker table tr td span.active.disabled:hover {
	background-color: #006dcc;
	background-image: -moz-linear-gradient(top, #0088cc, #0044cc);
	background-image: -ms-linear-gradient(top, #0088cc, #0044cc);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
	background-image: -webkit-linear-gradient(top, #0088cc, #0044cc);
	background-image: -o-linear-gradient(top, #0088cc, #0044cc);
	background-image: linear-gradient(top, #0088cc, #0044cc);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
	border-color: #0044cc #0044cc #002a80;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datetimepicker table tr td span.active:hover,
.datetimepicker table tr td span.active:hover:hover,
.datetimepicker table tr td span.active.disabled:hover,
.datetimepicker table tr td span.active.disabled:hover:hover,
.datetimepicker table tr td span.active:active,
.datetimepicker table tr td span.active:hover:active,
.datetimepicker table tr td span.active.disabled:active,
.datetimepicker table tr td span.active.disabled:hover:active,
.datetimepicker table tr td span.active.active,
.datetimepicker table tr td span.active:hover.active,
.datetimepicker table tr td span.active.disabled.active,
.datetimepicker table tr td span.active.disabled:hover.active,
.datetimepicker table tr td span.active.disabled,
.datetimepicker table tr td span.active:hover.disabled,
.datetimepicker table tr td span.active.disabled.disabled,
.datetimepicker table tr td span.active.disabled:hover.disabled,
.datetimepicker table tr td span.active[disabled],
.datetimepicker table tr td span.active:hover[disabled],
.datetimepicker table tr td span.active.disabled[disabled],
.datetimepicker table tr td span.active.disabled:hover[disabled] {
	background-color: #0044cc;
}

.datetimepicker table tr td span.active:active,
.datetimepicker table tr td span.active:hover:active,
.datetimepicker table tr td span.active.disabled:active,
.datetimepicker table tr td span.active.disabled:hover:active,
.datetimepicker table tr td span.active.active,
.datetimepicker table tr td span.active:hover.active,
.datetimepicker table tr td span.active.disabled.active,
.datetimepicker table tr td span.active.disabled:hover.active {
	background-color: #003399;
}

.datetimepicker table tr td span.old {
	color: #999999;
}

.datetimepicker th.switch {
	width: 145px;
}

.datetimepicker th span.glyphicon {
	pointer-events: none;
}

.datetimepicker thead tr:first-child th,
.datetimepicker tfoot tr:first-child th {
	cursor: pointer;
}

.datetimepicker thead tr:first-child th:hover,
.datetimepicker tfoot tr:first-child th:hover {
	background: #eeeeee;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i,
.input-group.date .input-group-addon span {
	cursor: pointer;
	width: 14px;
	height: 14px;
}


[class^="icon-"],
[class*=" icon-"] {
  display: inline-block;
  width: 14px;
  height: 14px;
  *margin-right: .3em;
  line-height: 14px;
  vertical-align: text-top;
  background-image:url(/assets/images/glyphicons-halflings.png);
  background-position: 14px 14px;
  background-repeat: no-repeat;
  margin-top: 1px;
}


.icon-th {  background-position: -240px 0;}

.input-append,
.input-prepend {
  margin-bottom: 5px;
  font-size: 0;
  white-space: nowrap;
}
.input-append input,
.input-prepend input,
.input-append select,
.input-prepend select,
.input-append .uneditable-input,
.input-prepend .uneditable-input,
.input-append .dropdown-menu,
.input-prepend .dropdown-menu {
  font-size: 14px;
}
.input-append input,
.input-prepend input,
.input-append select,
.input-prepend select,
.input-append .uneditable-input,
.input-prepend .uneditable-input {
  position: relative;
  margin-bottom: 0;
  *margin-left: 0;
  vertical-align: top;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.input-append input:focus,
.input-prepend input:focus,
.input-append select:focus,
.input-prepend select:focus,
.input-append .uneditable-input:focus,
.input-prepend .uneditable-input:focus {
  z-index: 2;
}
.input-append .add-on,.input-prepend .add-on {
  display: inline-block;
  width: auto;
  height: 40px;
  min-width: 16px;
  padding: 10px 5px 4px 5px;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 1px 0 #ffffff;
  background-color: #fff;
  border: 1px solid #ccc;
}
.input-append .add-on,
.input-prepend .add-on,
.input-append .btn,
.input-prepend .btn,
.input-append .btn-group > .dropdown-toggle,
.input-prepend .btn-group > .dropdown-toggle {
  vertical-align: top;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.input-append .active,
.input-prepend .active {
  background-color: #a9dba9;
  border-color: #46a546;
}
.input-prepend .add-on,
.input-prepend .btn {
  margin-right: -1px;
}
.input-prepend .add-on:first-child,
.input-prepend .btn:first-child {
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.input-append input,
.input-append select,
.input-append .uneditable-input {
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.input-append input + .btn-group .btn:last-child,
.input-append select + .btn-group .btn:last-child,
.input-append .uneditable-input + .btn-group .btn:last-child {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.input-append .add-on,
.input-append .btn,
.input-append .btn-group {
  margin-left: -1px;
}
.input-append .add-on:last-child,
.input-append .btn:last-child,
.input-append .btn-group:last-child > .dropdown-toggle {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.input-prepend.input-append input,
.input-prepend.input-append select,
.input-prepend.input-append .uneditable-input {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.input-prepend.input-append input + .btn-group .btn,
.input-prepend.input-append select + .btn-group .btn,
.input-prepend.input-append .uneditable-input + .btn-group .btn {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.input-prepend.input-append .add-on:first-child,
.input-prepend.input-append .btn:first-child {
  margin-right: -1px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.input-prepend.input-append .add-on:last-child,
.input-prepend.input-append .btn:last-child {
  margin-left: -1px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.input-prepend.input-append .btn-group:first-child {
  margin-left: 0;
}



/*--------------------------- Header Section --------------------------- */

#lower-form-maincontain { width:100%; float:left; }

.col-centered { float:none; margin:0 auto; }

#signup-form-maincontain { width:100%; float:left; }

#signup-form-maincontain h1 { width:100%; float:left; text-align:center; color:#fff; font-size:4em; font-weight:600; text-shadow:0px 2px 0px #216473; margin:0 0 25px 0; padding:0; }

#signup-form-maincontain h3 { width:100%; float:left; text-align:center; color:#fff; font-size:1.9em; font-weight:400; line-height:1.4em; text-shadow:0px 1px 0px #216473; margin:0 0 25px 0; padding:0; }

#signup-form-contain { width:100%; float:left; background:#f3fafc; border-radius:8px; padding:20px; }


/* Form */

#signup-form-contain label { width: 100%; float:left; padding:0px 9px; }

#signup-form-contain label span { color:#FF0004; }

#signup-form-contain input[type="email"]  { width: 100%; height: 40px; padding: 6px 9px; margin-bottom:5px; font-size: 14px; line-height: 1.42857143; color:#585858; background:url(/assets/images/email-icon.png) no-repeat right #f4fbfd;  background-position: 98% 50%;  border:none; border-bottom:1px solid #e5e5e5; border-radius:0px; -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }
#signup-form-contain input[type="email"]:focus { border-color: #1885aa; outline: 0; }


#signup-form-contain input#company-name  { width: 100%; height: 40px; padding: 6px 9px; margin-bottom:5px; font-size: 14px; line-height: 1.42857143; color:#585858; background:url(/assets/images/company-icon.png) no-repeat right #f4fbfd; background-position: 98% 50%; border:none; border-bottom:1px solid #e5e5e5; border-radius:0px; -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }
#signup-form-contain input#company-name:focus { border-color: #1885aa; outline: 0; }


#signup-form-contain input#user-name  { width: 100%; height: 40px; padding: 6px 9px; margin-bottom:5px; font-size: 14px; line-height: 1.42857143; color:#585858; background:url(/assets/images/user-icon.png) no-repeat right #f4fbfd; background-position: 98% 50%; border:none; border-bottom:1px solid #e5e5e5; border-radius:0px; -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }
#signup-form-contain input#user-name:focus { border-color: #1885aa; outline: 0; }


#signup-form-contain input#phone-no  { width: 100%; height: 40px; padding: 6px 9px; margin-bottom:5px; font-size: 14px; line-height: 1.42857143; color:#585858; background:url(/assets/images/phone-icon.png) no-repeat right #f4fbfd; background-position: 98% 50%; border:none; border-bottom:1px solid #e5e5e5; border-radius:0px; -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }
#signup-form-contain input#phone-no:focus { border-color: #1885aa; outline: 0; }


#signup-form-contain input[type="password"]  { width: 100%; height: 40px; padding: 6px 9px; margin-bottom:5px; font-size: 14px; line-height: 1.42857143; color:#585858; background:#f4fbfd; background-position: 98% 50%; border:none; border-bottom:1px solid #e5e5e5; border-radius:0px; -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }
#signup-form-contain input[type="password"]:focus { border-color: #1885aa; outline: 0; }


#signup-form-contain input[type="submit"],#signup-form-contain input[type="button"],#signup-form-contain input[type="reset"] { width:100%; float:left; font-family: 'Lato', sans-serif; font-size:1.4em; font-weight:700; color:#fff; text-decoration:none; text-transform:capitalize; border-radius:4px; padding:12px 12px; background:#f7943d; border:1px solid #f7943d; border-bottom:2px solid #ce6f1b; text-shadow:0px 2px 0px #ce6f1b;  }

#signup-form-contain input[type="submit"]:focus,#signup-form-contain input[type="button"]:focus,#signup-form-contain input[type="reset"]:focus { color:#fff; text-decoration:none; border-radius:4px; border:1px solid #017ea4; background:#017ea4; border-bottom:2px solid #005c77; -webkit-transition:background 0.6s; -moz-transition:background 0.6s;  -o-transition:background 0.6s;  transition:background 0.6s; }

#signup-form-contain input[type="submit"]:hover,#signup-form-contain input[type="button"]:hover,#signup-form-contain input[type="reset"]:hover { color:#fff; text-decoration:none; border-radius:4px; border:1px solid #017ea4; background:#017ea4; border-bottom:2px solid #005c77; -webkit-transition:background 0.6s; -moz-transition:background 0.6s;  -o-transition:background 0.6s;  transition:background 0.6s; }


/* Copyright Text */

#copyright { width:100%; float:left; color:#fff; text-align:center; margin:45px 0 15px 0; }



/*--------------------------- Dashboard Page Section --------------------------- */


/* Header Section */

#header-2 { width:100%; float:left; background:#fff; padding-bottom:10px; border-bottom:6px solid #007e99; }

#logo-2 { width:100%; float:left; text-align:center; margin:15px 0 0 0; padding:0; }

#dashboard-title { width:100%; float:left; font-size:1.6em; color:#7e7e7e; font-weight:600; text-align:center; margin:20px 0 0 0; padding:0; }



/* Header Right Side */

#right-header2 { width:100%; float:left; }

.right-head { display:table-cell; float:right; }

.right-head2 { display:table-cell; float:left; }

#setting-contain { width:100%; float:left; font-size:1.5em; text-align:center; margin:25px 15px 0px 0px;  }
#setting-contain a { color:#7e7e7e; text-decoration:none; }

#noti-contain { width:100%; float:left; font-size:1.5em; text-align:center; margin:25px 0px 0px 0px; }
#noti-contain a { color:#7e7e7e; text-decoration:none; }


/* Sign Up Page */

#login-contain { width:100%; float:left; margin:15px 0px 0px 0px;  }

.nav1 > li > a.login-2 { color:#34474f; position: relative; font-weight:700;  display: block; text-align:center; border-left:1px solid #ced7e1;  }

.nav1 > li > a.login-2 > img { width:40px; height:40px; margin-right:5px; border-radius:50%; }

.nav1 > li > a.login-2:hover,.nav1 > li > a.login-2:focus { text-decoration: none; }


/* mobile fix */
@media (max-width: 768px) {

.nav1 > li > a.login-2 { color:#34474f; position: relative; font-weight:700;  display: block; text-align:center; border-left:none;  }	

}


/* CSS used here will be applied after bootstrap.css */

.notifications {   /* min-width:420px; */  }
  
.glyphicon-bell { font-size:2rem;  }  
  
.notifications-wrapper { overflow:auto;  max-height:250px;    }
   
.menu-title {  color:#007e99;  font-size:1.2rem; text-transform:uppercase; font-weight:600; display:inline-block;   }
  
.glyphicon-circle-arrow-right { margin-left:5px; }
  
#notification-heading {	padding:2px 15px; border-bottom:1px solid #dddfe2;  }

#notification-footer  {	padding:2px 15px; border-top:1px solid #dddfe2;  }          
#notification-footer a { color:#007e99; text-decoration:none;  }
        

.notifications-wrapper ul { width:100%; float:left; margin:0; padding:0; list-style:none; }
.notifications-wrapper ul li { width:100%; float:left; list-style:none; list-style-image:none; padding:10px 0px 10px 15px; background:#fff; border-top:1px solid #dddfe2; }
.notifications-wrapper ul li:first-child { border:none; }
.notifications-wrapper ul li:hover { background:#b9f3ff; -webkit-transition:background 0.6s; -moz-transition:background 0.6s;  -o-transition:background 0.6s;  transition:background 0.6s; }

.note-pic { width: 16.66666667%; float:left; }
.note-pic img { width:50px; height:50px; float:left; border-radius:4px; }

.note-text { width: 83.33333333%; float:left; padding:0 15px; }
.note-text h4 { width:100%; float:left; font-size:1.3rem; margin:0 0 5px 0; padding:0;  }
.note-text h4 strong { color:#232323; text-transform:capitalize;  }
.note-text p { width:100%; float:left; font-size:1rem; margin:0 0 5px 0; padding:0;  }
.note-text p i { font-size:1.2rem; margin:0 5px 0px 0; padding:0;  }


.badge1 {  display: inline-block;  min-width: 25px;  min-height:25px;  padding:5px 5px 5px 5px;  font-size: 14px;  font-weight: bold; line-height: 1;
  color: #fff;  text-align: center;  white-space: nowrap;  vertical-align: middle;  background-color: #ff7532;  border-radius:50%;  position:absolute;
  z-index:999;  margin:-10px 0 0 10px;  }


/*--------------------------- Lower Main Section --------------------------- */


#lower-main-contain { width:100%; float:left; background:#232323; }


/* Left Side Navigation Section */

#sidebar { background:#232323;  }

#sidebar .nav .open > a,#sidebar .nav .open > a:hover,#sidebar .nav .open > a:focus { color:#fff; background:#080808 url(/assets/images/nav-line.jpg) repeat-y left top; -webkit-transition:background 0.6s; -moz-transition:background 0.6s;  -o-transition:background 0.6s;  transition:background 0.6s; }

.nav-bg { background:#232323; }


/* Right Side Contain Section */

#right-main-contain { width:100%; float:left; background:#e2e3e4; }

.page-title { width:100%; float:left; font-size:2.3rem; color:#4e4e4e; font-weight:600; background:#f8f8f8; border-bottom:1px solid #cdcdcd; margin:0 0 25px 0; padding:15px 15px; }

.page-title2 { width:100%; float:left; font-size:2.3rem; color:#4e4e4e; font-weight:600; background:#f8f8f8; border-bottom:1px solid #cdcdcd; margin:0; padding:15px 15px; }

.page-title2 span { font-size:1.4rem; color:#969696; font-weight:300; }
.page-title2 span i { margin:0 5px; }


.summary-top-contain { width:100%; float:left; }

.summary-top-contain h3 { width:100%; float:left; font-size:2rem; color:#4e4e4e; font-weight:400; margin:0 0 25px 0; padding:0 15px; }

.summary-box { width:100%; float:left; border-radius:6px; border:1px solid #c7c7c7; background:#fff; box-shadow:0px 1px 0px #cbcccd; margin-bottom:25px;  }

.summary-icon { width:100%; float:left; text-align:center; padding:15px 15px; background:#fafafa; border-bottom:1px solid #c7c7c7; border-top-left-radius:6px; border-top-right-radius:6px; }

.summary-text { width:100%; float:left; text-align:center; font-size:1.2rem; color:#231f20; text-transform:uppercase; padding:10px 10px 10px 10px; }
.summary-text h2 { width:100%; float:left; font-size:2rem; color:#007ea4; font-weight:400; margin:5px 0 5px 0; padding:0; }  
.summary-text h2 sup { font-size:1.2rem; } 




/* Left Side */


.white-box-contain { width:100%; float:left; border-radius:4px; padding-top:15px; border-top:3px solid #99cbdb; border-left:1px solid #bed0d5; border-right:1px solid #bed0d5; border-bottom:1px solid #bed0d5; background:#fff; box-shadow:0px 1px 0px #cbcccd; margin-bottom:25px; padding-bottom:15px;  }



/* Graph Chart */

.white-title-contain { width:100%; float:left; }

.white-box-title { width:100%; float:left; font-size:2rem; color:#231f20; font-weight:600; margin:0px 0 15px 0; padding:0; }

.graph-year-select { width:100%; float:left; font-size:1.2rem; color:#34474f; margin:5px 0 15px 0; }

.graph-filter { width:100%; float:left; color:#34474f; margin:0px 0 15px 0; }

.graph-dropdown { background:none; border:1px solid #dbdbe4; border-radius:25px; margin-right:5px; }
.graph-dropdown:hover { background:none; border:1px solid #2cb5d3; }
.graph-dropdown span.glyphicon { font-size:12px; margin-left:2px; }

.left-ruler-contain { width:5%; float:left; padding:15px 0px 0px 15px; }

.graph-chart-contain { width:92%; float:left; padding:0px 0px 0px 15px; }

.bottom-ruler-contain { width:100%; float:left; padding:15px 0px 15px 0px;  }


/* Backup Options */

.table-contain { width:100%; float:left;  }



/* Right Recent Activity Section */

.white-box-title1 { width:100%; float:left; font-size:2rem; color:#231f20; font-weight:600; margin:0; padding:0 15px 15px 15px; border-bottom:1px solid #e2e9ee; }

.recent-activity-contain { width:100%; float:left; border-left:1px solid #e5e5e5; padding-top:25px; }

.activity-quote-contain { width:100%; float:left; border:1px solid #eee; padding:15px; border-radius:4px; margin:0px 0px 25px 15px;  }
.activity-quote-contain h5 { width:100%; float:left; font-size:14px; font-weight:600; margin:-15px 0 5px 0; padding:0; text-transform:capitalize; }
.activity-quote-time { width:100%; float:left; margin-bottom:5px; }
.activity-quote-edge { width:100%; float:left; height:20px; background:url(/assets/images/green-dot.png) no-repeat left; margin:0px 0px 0px -55px; }
.activity-quote-text { width:100%; float:left; }

.activity-pdf-contain { width:100%; float:left; margin-top:15px; }
.activity-pdf-contain span i { float:left; background:#f5f5f5; font-size:21px; color:#007ea4; border-radius:4px; padding:10px 10px 10px 10px; margin:0px 15px 0px 0px; }

.activity-pdf-text { float:left;  }
.activity-pdf-text i { margin-right:5px; }

.activity-load-more { width:100%; float:left; text-align:center; font-weight:600; border-top:1px solid #e2e9ee; text-transform:capitalize; padding:15px 15px 0 15px;  }




/*-------------------------------------- Quotes Page -------------------------------------- */


/* Right Side Collapse */

#quote-collapse-contain { width:100%; float:left; background:#fff; }

.quote-page-title { width:100%; float:left; font-size:2.1rem; color:#231f20; font-weight:600; margin:0; padding:15px 0; }



/* Left Quote Table Content */

#left-collapse-content { display: block; width: 560px; position:absolute; min-height: 100%;  }

#collapse-table-contain { width:100%; float:left; padding:0 15px; }

.table-responsive { width:100%; }

.table-pagination { width:100%; float:left; margin-bottom:45px; }


#right-swap-maincontain {  width: 100%;  height: auto;  display: block;  background: #e2e3e4;  min-height: 800px;  overflow: hidden;  position: relative; z-index: 2; }


#right-swap-maincontain nav { position:absolute; width:30px;  min-height: 100%;  padding:15px 0 25px 0;  background:#fff url(/assets/images/more-txt.png) no-repeat center top;  border-right:1px solid #bed0d5; }

#swipeable-arrow { margin:0; padding:0;  list-style: none; }
#swipeable-arrow li { display: block; font-size: 2.5rem; font-weight: bold; margin:0; padding:0; }
#swipeable-arrow li a { width:100%; float:left; text-align:center;  display: block; color: #232323; text-decoration: none; }
#swipeable-arrow li a:hover, #swipeable-arrow li a.open { color: #232323; text-decoration:none;  }


/* top navigation */
#right-swap-maincontain nav#top {  position: absolute;  height: 120px;   width: 100%;  min-height: 120px;  top: 0;  text-align: center;  padding: 20px 0px;
  background: #343638; }

nav#top #swipeable-arrow li { display: inline-block;   margin-right: 6px; }


/* bottom navigation */
#right-swap-maincontain nav#bottom { position: fixed;  height: 120px;   width: 100%;  min-height: 120px;  bottom: 0;  text-align: center; padding: 20px 0px;
  background: rgba(52,54,56,0.6); }

nav#bottom #swipeable-arrow li { display: inline-block; margin-right: 6px; }

.fixed-btm { padding-bottom: 120px; /* add extra space when nav is fixed at the bottom */ }


/* Right Quote Content */

#right-swap-incontain { display: block;  margin-left: 40px; padding-top:45px; }

#right-title-contain { width:100%; float:left; color:#333; font-size:2.1rem; margin:0 0 15px 0; padding:0 0 0px 0px; border-bottom:1px solid #b5b6b6; }

#right-title-contain h1 { width:100%; float:left; color:#333; font-size:2.1rem; background:url(/assets/images/battery-icon2.png) no-repeat left top; margin:10px 0 15px 0; padding:0 0 5px 53px; }

#right-title-button { width:100%; float:left; margin-bottom:15px; }

.pdf-icon { width:20px; height:20px; float:left; background:url(/assets/images/pdf-icon.png) no-repeat; margin-right:10px; }
.download-icon { width:14px; height:20px; float:left; background:url(/assets/images/download-icon.png) no-repeat; margin-right:10px; }


/* Top Dash Box Contain */

.top-dashbox-contain { width:100%; float:left; }


/* Quote Graph Box Contain */

/* Checkbox and  Radio */

.graph-year-select2 { width:100%; float:left; font-size:1.2rem; color:#34474f; margin:5px 0 15px 0; }

.graph-year-select2 input[type=checkbox]:not(old),.graph-year-select2 input[type=radio]:not(old){
  width     : 2em;
  margin    : 0;
  padding   : 0;
  font-size : 1em;
  opacity   : 0;
}

.graph-year-select2 input[type=checkbox]:not(old) + label,.graph-year-select2 input[type=radio]:not(old) + label{
  display      : inline-block;
  margin-left  : -2em;
  line-height  : 1.4em;
}

.graph-year-select2 input[type=checkbox]:not(old) + label > span,.graph-year-select2 input[type=radio]:not(old) + label > span{
  display          : inline-block;
  width            : 16px;
  height           : 16px;
  margin           : 0px 5px 0px 10px;
  border           : 0;
  background       : #007ea4;  
  border-radius    : 2px;
  vertical-align   : bottom;
}

.graph-year-select2 input[type=checkbox]:not(old):checked + label > span,.graph-year-select2input[type=radio]:not(old):checked + label > span{ }

.graph-year-select2 input[type=checkbox]:not(old):checked + label > span:before{
  content     : '✓';
  display     : block;
  width       : 1em;
  color       : #f7943d;
  font-size   : 0.875em;
  line-height : 1em;
  text-align  : center;
  font-weight : bold;
}

.graph-year-select2 input[type=radio]:not(old):checked +  label > span > span{
  display          : block;
  width            : 16px;
  height           : 16px;
  margin           : 0;
  border           : 0;
  border-radius    : 2px;
  background       : #f7943d;
}



/* Ruler */

.range-bar {
  background-color: #a9acb1;
  border-radius: 15px;
  display: block;
  height: 4px;
  position: relative;
  width: 100%;
}

.range-quantity {
  background-color: #0baacc;
  border-radius: 15px;
  display: block;
  height: 100%;
  width: 0;
}

.range-handle {
  background:url(/assets/images/ruler-button.png) no-repeat center;
  border-radius: 100%;
  cursor: move;
  height: 29px;
  left: 0;
  top: -12px;
  position: absolute;
  width: 29px;
}

.range-min,
.range-max {
  color: #181819;
  font-size: 12px;
  height: 20px;
  padding-top: 4px;
  position: absolute;
  text-align: center;
  top: -9px;
  width: 24px;
}

.range-min {
  left: -30px;
}

.range-max {
  right: -30px;
}

/**
 * Vertical slider style.
 */

.vertical {
  height: 100%;
  width: 4px;
}

.vertical .range-quantity {
  bottom: 0;
  height: 0;
  position: absolute;
  width: 100%;
}

.vertical .range-handle {
  bottom: 0;
  left: -12px;
  top: auto;
}

.vertical .range-min,
.vertical .range-max {
  left: -10px;
  right: auto;
  top: auto;
}

.vertical .range-min {
  bottom: -30px;
}

.vertical .range-max {
  top: -30px;
}

/**
 * Style for disabling text selection on handle move.
 */

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/**
 * Style for handle cursor on disabled slider.
 */

.range-disabled {  cursor: default; }

.slider-wrapper {  margin:15px 15px 0px 0px;  position: relative;  width:100%; }

.slider-wrapper2 {  margin:-15px 15px 0px 15px;  position: relative;  width:100%; float:left; }

.vertical-wrapper {  height: 300px;  width: auto; }

.vertical-wrapper2 {  height: 360px;  width: auto; }

input#radio4 .quote-radio { background:#f7943d; }



/* Graph Table */

.graph-table { width:100%; float:left; margin:15px 0 25px 0; color:#000; border-radius:6px; }

.graph-table ul { width:100%; float:left; margin:0; padding:0; list-style:none; }

.graph-table ul li { width:20%; float:left; list-style:none; list-style-image:none; display:inline; padding:12px 15px; margin:0px 0px 0px 0px; text-align:center; background: url(/assets/images/gray-line.jpg) no-repeat left #f7f7f7; border:1px solid #ddd; border-right:none; border-left:none; }

.graph-table ul li:first-child { width:20%; float:left; text-align:left; background:#007ba2; color:#fff; font-size:2rem; font-weight:600; padding:12px 15px;  border-top-left-radius:4px; border-bottom-left-radius:4px;  border:1px solid #007ba2; }

.graph-table ul li:first-child h4 { color:#fff; font-size:2rem; font-weight:600; margin:5px 0 0px 0; padding:0; }

.graph-table ul li:last-child { border-top-right-radius:4px; border-bottom-right-radius:4px; border:1px solid #ddd; }

.table-savepic { width:58px; height:53px; background:url(/assets/images/saving-icon2.png) no-repeat left; float:left; margin:0px 15px 0px 0px; }

.graph-table ul li h4 { color:#007ea4; font-size:2.1rem; font-weight:500; margin:5px 0 5px 0; padding:0; }

.graph-table ul li h6 { color:#000; font-size:1.3rem; margin:5px 0 5px 0; padding:0; }

.graph-table ul li:hover { background:#007ba2; color:#fff; border-top:1px solid #007ba2; border-bottom:1px solid #007ba2; }

.graph-table ul li:hover h4 { color:#fff; }

.graph-table ul li:hover h6 { color:#fff; }



/* Product Costing */

.table-title { background:#0085ab; color:#fff; padding:0 25px; }
.table-title h5 { color:#fff; font-weight:600; }

.table-center { text-align:center; }
.table-right { text-align:right; }

.table-gray { background:#f9f9f9;  }
.table-gray h5 { color:#007ea4; font-weight:600; margin:0; padding:2px 0 0 0; }

.table-border { width:100%; float:left; margin:0; padding:0; border:none; }
.table-border th,.table-border td {  border-top: 1px solid #ddd !important; border-right: 1px solid #ddd !important; padding:10px;  }
.table-border tr:first-child { border-top:none!important; border-right:none!important; }



/* ElectraGen Section */

#electragen-content { width:100%; float:left; padding:0 15px; }

#electragen-content h1 { color:#0085ab; font-weight:600; margin:15px 0 25px 0; padding:0;  }
#electragen-content h1 sup { font-size:2rem; }

#electragen-content h2 { color:#0085ab; font-size:2.5rem; font-weight:600; margin:15px 0 15px 0; padding:0;  }

#electragen-content summary { width:100%; float:left; margin-bottom:25px; }
#electragen-content summary img { float:right; margin:0 0 5px 25px; }

#electragen-content summary ul { margin:0; padding:0; list-style:none; }


.technical-specs { width:100%; float:left; margin-bottom:45px; }
.technical-specs img { width:100%; float:left; border:1px solid #c1c1c1; }


/* Key Contacts */

.table-bottom-border { border-bottom:4px solid #0aaacc; }

.table-gray2 { background:#f9f9f9;  }
.table-gray2 h4 { color:#4e4e4e; font-weight:600; margin:5px 0 10px 0; padding:0; }
.table-gray2 em { width:100%; float:left; margin-bottom:10px; }

.contact-email { background:url(/assets/images/email-icon2.png) no-repeat left; padding-left:30px; margin-right:10px; }
.contact-email a { color:#4e4e4e; text-decoration:none; }
.contact-email a:hover { color:#f7943d; text-decoration:underline; }

.contact-phone { background:url(/assets/images/phone-icon2.png) no-repeat left; padding-left:15px; margin-right:10px; }



/*--------------------------- Backup Options Page Section --------------------------- */

.accordation-main-contain { width:100%; float:left; margin-bottom:25px; }

.tab-content { width:100%; float:left; border-radius:4px; border-top-left-radius:0; padding:15px 15px 0px 15px; border:1px solid #bed0d5; background:#fff; box-shadow:0px 1px 0px #cbcccd; margin:0px 0px 0px 0px; }


.inner-white-boxcontain { width:100%; float:left; border-radius:4px; border-top:3px solid #99cbdb; border-left:1px solid #bed0d5; border-right:1px solid #bed0d5; border-bottom:1px solid #bed0d5; background:#fff; box-shadow:0px 1px 0px #cbcccd; margin-bottom:15px; padding:15px 15px 0 15px;  }


.blue-border { width:100%; float:left; background:url(/assets/images/blue-arrow.png) no-repeat right; border-bottom:2px solid #009ccb;  }


/* Form Title */

.form-title { width:100%; float:left; color:#333; text-transform:uppercase; border-bottom:1px dashed #bdbdbd; margin:0; padding:8px 0; margin-bottom:15px; }

.form-button-contain { width:100%; float:left; border-top:1px dashed #bdbdbd; padding-top:15px; margin-top:10px; }


.form-button-contain input[type="submit"],.form-button-contain input[type="button"] { font-family: 'Lato', sans-serif; font-size:1em; font-weight:700; float:left; color:#fff; text-decoration:none; text-transform:uppercase; border-radius:4px; padding:8px 15px; margin-right:10px; background:#5cb85c; border:1px solid #5cb85c; border-bottom:2px solid #57922c; }

.form-button-contain input[type="submit"]:focus,.form-button-contain input[type="button"]:focus { color:#929292; text-decoration:none; border-radius:4px;  background:#fff; border:1px solid #c5c5c5; border-bottom:2px solid #c5c5c5; -webkit-transition:background 0.6s; -moz-transition:background 0.6s;  -o-transition:background 0.6s;  transition:background 0.6s; }

.form-button-contain input[type="submit"]:hover,.form-button-contain input[type="button"]:hover { color:#929292; text-decoration:none; border-radius:4px;  background:#fff; border:1px solid #c5c5c5; border-bottom:2px solid #c5c5c5; -webkit-transition:background 0.6s; -moz-transition:background 0.6s;  -o-transition:background 0.6s;  transition:background 0.6s; }


.form-button-contain input[type="reset"] { font-family: 'Lato', sans-serif; font-size:1em; font-weight:700; float:left; color:#929292; text-decoration:none; text-transform:uppercase; border-radius:4px; padding:8px 15px; margin-right:10px; background:#fff; border:1px solid #c5c5c5; border-bottom:2px solid #c5c5c5; }

.form-button-contain input[type="reset"]:focus { color:#fff; text-decoration:none; border-radius:4px; background:#5cb85c; border:1px solid #5cb85c; border-bottom:2px solid #57922c; -webkit-transition:background 0.6s; -moz-transition:background 0.6s;  -o-transition:background 0.6s;  transition:background 0.6s; }

.form-button-contain input[type="reset"]:hover { color:#fff; text-decoration:none; border-radius:4px; background:#5cb85c; border:1px solid #5cb85c; border-bottom:2px solid #57922c; -webkit-transition:background 0.6s; -moz-transition:background 0.6s;  -o-transition:background 0.6s;  transition:background 0.6s; }




/*--------------------------- Footer Contain Section --------------------------- */

#footer { width:100%; float:left; background:#fcfcfc; border-left:1px solid #cfdbe2; border-top:1px solid #cfdbe2; padding:15px; font-size:12px; color:#969696; text-transform:capitalize; }